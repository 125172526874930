import { PARENT_INTEGRATION } from '@clipr/shared-types'
import uniqueId from 'lodash/uniqueId'
import { adsShortCodes } from '~/utilities/constants'
import storiesTextStickersPrepare, {
  storiesTextStickersPrepareItem,
} from '~/components/stories-base/globalInit/storiesTextStickersPrepare'

declare global {
  interface Window {
    AylTag: any
  }
}

export function checkForAYLTag(count = 0) {
  if (typeof window.AylTag === 'undefined') {
    count++
    // eslint-disable-next-line prefer-promise-reject-errors
    if (count >= 60) return Promise.reject()

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(checkForAYLTag(count))
      }, 200)
    })
  } else {
    return Promise.resolve()
  }
}

export function generateBlankStoryData(slug: string) {
  const randomId = uniqueId()

  return {
    isAd: true,
    adSeen: false,
    adCount: null,
    active: 1,
    short_code: adsShortCodes.MIDROLL,
    adPlacement: adsShortCodes.MIDROLL,
    slug: `${slug}_${randomId}`,
    type: 'ACTION_NO_CTA',
    description: {
      content: '',
      image: null,
    },
    content: {
      logo_top_left_img: '',
      logo_top_left_title: '',
      logo_top_left_sponsored: 0,
      button_hidden: 0,
      button_above_title: '',
      button_title: '',
      button_color_id: '',
      button_color_is_static: false,
      external_url: '',
      button_url: '',
      button_url_enable_iframe: false,
      iframe_title: '',
      story: [
        {
          isAd: true,
          id: `story-${randomId}`,
          aylElement: null,
          position: 1,
          type: 'image',
          ext: 'jpeg',
          source: null,
          duration: 5000,
          size: {
            width: 339,
            height: 533,
            ratio: 0.66,
          },
          stickers: [],
          filter: null,
          overlay: {
            color: '#000',
            opacity: 0,
          },
          bgColor: '#ffffff',
          isFullScreen: false,
          thumbnail: '',
          src: '',
        },
      ],
      info_url: '',
    },
  }
}

function getParentWindow(win: Window | null = null): Window | null {
  try {
    if (win === window.parent) return win
    return getParentWindow(window.parent)
  } catch (error) {
    return null
  }
}

export function changeParentUrl(url: string) {
  const win = getParentWindow()
  if (win === null) {
    window.parent.postMessage(
      {
        key: 'CHANGE_PARENT_URL',
        value: url,
      },
      /* host */ '*',
    )
    return false
  } else {
    try {
      win.location = url
      return true
    } catch (error) {
      window.parent.postMessage(
        {
          key: 'CHANGE_PARENT_URL',
          value: url,
        },
        /* host */ '*',
      )
      return false
    }
  }
}

const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // validate fragment locator
  return !!urlPattern.test(urlString)
}

export function getReferrerURL() {
  if (!process.client) return ''

  const isInIframe = window !== window.top
  if (!isInIframe) return ''

  const params = new URLSearchParams(window.location.search)
  const referrer = params.get('hostDom')
  if (referrer) return referrer

  // ancestorOrigins return a list of the enclosing frames:
  if (
    'ancestorOrigins' in window.location &&
    window.location.ancestorOrigins.length
  ) {
    const ancestorOrigins = window.location.ancestorOrigins
    if (isValidUrl(ancestorOrigins[ancestorOrigins.length - 1]))
      return new URL(ancestorOrigins[ancestorOrigins.length - 1]).hostname
  }

  try {
    if (window.top) {
      return new URL(window.top.location.href).hostname
    }
  } catch (e) {}

  return ''
}

// REFERENCE UNICODE TABLES:
// http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
// http://www.tamasoft.co.jp/en/general-info/unicode.html
//
// TEST EDITOR:
// http://www.gethifi.com/tools/regex
//
// UNICODE RANGE : DESCRIPTION
//
// 3000-303F : punctuation
// 3040-309F : hiragana
// 30A0-30FF : katakana
// FF00-FFEF : Full-width roman + half-width katakana
// 4E00-9FAF : Common and uncommon kanji
//
// Non-Japanese punctuation/formatting characters commonly used in Japanese text
// 2605-2606 : Stars
// 2190-2195 : Arrows
// u203B     : Weird asterisk thing

const japaneseRegex =
  /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|\u203B/g
export function hasJapaneseCharacters(str: string) {
  return japaneseRegex.test(str)
}
export function formatTextStickers({ stories = [] } = {}) {
  stories.forEach((storyData) => {
    // @ts-ignore
    storyData.content.story.forEach((story) => {
      story.stickers = story.stickers
        .map((storySticker: any) => {
          if (storySticker.type === 'text') {
            storiesTextStickersPrepareItem(storySticker.sticker)
          }
          return storySticker
        })
        .filter((sticker: any) => sticker)
    })
  })
}
export function generateToken(length = 12) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let result = ''
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
