/* eslint-disable */
import Vue from 'vue'

const mobileAds = {
  isMraid: false,
  isDapi: false,
  pauseMethod: null,
  resumeMethod: null,
  isAdVisible: false,
  open(url, event) {
    if (!mobileAds.isActive()) {
      return false
    }
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    if (mobileAds.isMraid) {
      window.parent.postMessage(
        {
          key: 'MRAID_URL',
          value: url
        },
        '*'
      )

      if (!mraid.open) {
        return false
      }
      mraid.open(url)
    } else if (mobileAds.isDapi) {
      if (!dapi.openStoreUrl) {
        return false
      }
      dapi.openStoreUrl()
    }
  },
  toggleDapi(value) {
    mobileAds.isDapi = value
    if (value === true) {
      mobileAds.setupDapi()
    }
  },
  toggleMraid(value) {
    mobileAds.isMraid = value
    if (value === true) {
      try {
        mobileAds.setupMraid()
      } catch (e) {
        window.mraid = {
          open: function() {},
          addEventListener: function() {},
          getState: function() {},
          isViewable: function() {}
        }
      }
    }
  },
  isActive() {
    return mobileAds.isDapi || mobileAds.isMraid
  },
  setupDapi() {
    if (dapi.isReady()) {
      mobileAds.dapiReady()
    } else {
      dapi.addEventListener('ready', mobileAds.dapiReady)
    }
  },
  setupMraid() {
    if (mraid.getState() === 'default') {
      mobileAds.mraidReady()
    } else {
      mraid.addEventListener('ready', mobileAds.mraidReady)
    }
  },
  registerResumeMethod(callback) {
    mobileAds.resumeMethod = callback
    // if method is registered after the first visibility event, we want to ensure that play/pause is correctly applied
    mobileAds.applyResumeOrPause()
  },
  registerPauseMethod(callback) {
    mobileAds.pauseMethod = callback
    // if method is registered after the first visibility event, we want to ensure that play/pause is correctly applied
    mobileAds.applyResumeOrPause()
  },
  applyResumeOrPause() {
    if (mobileAds.isAdVisible && mobileAds.resumeMethod)
      mobileAds.resumeMethod()
    else if (!mobileAds.isAdVisible && mobileAds.pauseMethod)
      mobileAds.pauseMethod()
  },
  dapiReady() {
    // set dapi specific events
    dapi.removeEventListener('ready', mobileAds.dapiReady)
    mobileAds.isAdVisible = dapi.isViewable()
    mobileAds.applyResumeOrPause()

    const dapiScreenSize = dapi.getScreenSize()
    const dapiVolume = dapi.getAudioVolume()

    dapi.addEventListener('adResized', () => {})
    dapi.addEventListener('audioVolumeChange', () => {})
    dapi.addEventListener('viewableChange', e => {
      mobileAds.isAdVisible = e.isViewable
      mobileAds.applyResumeOrPause()
    })
  },
  mraidReady() {
    mraid.getMaxSize()
    mraid.addEventListener('viewableChange', isViewable => {
      mobileAds.isAdVisible = isViewable
      mobileAds.applyResumeOrPause()
    })
    mraid.addEventListener('stateChange', state => {
      if (state === 'expanded') {
        mobileAds.isAdVisible = true
      } else if (state === 'hidden') {
        mobileAds.isAdVisible = false
      }

      mobileAds.applyResumeOrPause()
    })

    if (mraid.isViewable() || mraid.getState() === 'expanded') {
      mobileAds.isAdVisible = true
      mobileAds.applyResumeOrPause()
    }
  }
}

Vue.use({
  install(Vue) {
    Vue.prototype.$mobileAds = mobileAds
  }
})
