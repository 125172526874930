export default function (req, res, next) {
  if (
    (req.method === 'GET' && req.originalUrl === '/infos') ||
    (req.method === 'POST' && req.originalUrl === '/')
  ) {
    res.statusCode = 200
    res.setHeader('Content-Type', 'application/json')
    res.end(JSON.stringify({ status: 'ok' }))
  } else {
    next()
  }
}
