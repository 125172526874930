import { defineStore } from 'pinia'

/**
 * Current implementation of impressions pixels
 * Used as single source of impressions in every story types
 * In root lever we display <img/> tags according to availablePixels
 * Can be disabled in some modes like VAST / VPAID
 */
export const usePixelsStore = defineStore('pixels', {
  state: () => ({
    disabled: false,
    pixels: [] as string[],
  }),
  getters: {
    /**
     * Pixel impressions could be disabled in some cases like VAST / VPAID
     * We perform native player's event tracking there
     */
    availablePixels: (state): string[] => (state.disabled ? [] : state.pixels),
  },
  actions: {
    setDisabled(disabled: boolean) {
      this.disabled = disabled
    },
    addPixel(pixel: string) {
      if (!this.pixels.includes(pixel)) {
        this.pixels.push(pixel)
      }
    },
  },
})
