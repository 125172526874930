import { getStickerFontName } from './fontsInit/commonFunctions'

export default function (storyStickers) {
  let updated = false
  storyStickers.forEach(storySticker => {
    updated |= storiesTextStickersPrepareItem(storySticker.sticker)
  })
  return updated
}

export function storiesTextStickersPrepareLibrary(stickers) {
  let updated = false
  stickers.forEach(sticker => {
    updated |= storiesTextStickersPrepareItem(sticker)
  })
  return updated
}

export function storiesTextStickersPrepareItem(sticker) {
  let updated = false
  if (sticker.type === 'text') {
    updated =
      transformOldTextStickerToVersion2022(sticker) | fillTextSticker2022WithDefaultProps(sticker)
  }
  return updated
}

function fillTextSticker2022WithDefaultProps(sticker) {
  let updated = false
  if (!sticker.customize) {
    sticker.customize = { blocks: [{ textStyle: {}, blockStyle: {} }] }
    updated = true
  } else if (!sticker.customize.blocks) {
    sticker.customize.blocks = [{ textStyle: {}, blockStyle: {} }]
    updated = true
  } else {
    if (!Array.isArray(sticker.customize.blocks)) {
      sticker.customize.blocks = [sticker.customize.blocks]
      updated = true
    }

    sticker.customize.blocks.forEach(block => {
      if (!block.textStyle) {
        block.textStyle = {}
        updated = true
      }
      if (!block.blockStyle) {
        block.blockStyle = {}
        updated = true
      }
    })
  }
  return updated
}

function transformOldTextStickerToVersion2022(sticker) {
  if (sticker.type === 'text' && !sticker.hasOwnProperty('version')) {
    // base
    if (!sticker.hasOwnProperty('customize')) sticker.customize = {}
    if (!sticker.customize.hasOwnProperty('blocks')) {
      sticker.customize.blocks = [
        {
          textStyle: sticker.customize.content,
          text: sticker.customize.content.lines[0]
        }
      ]
      delete sticker.customize.blocks[0].textStyle.lines
      delete sticker.customize.content

      // textStyle
      const textStyle = sticker.customize.blocks[0].textStyle
      textStyle.fontFamily = getStickerFontName(textStyle.fontFamily)
      textStyle.lineHeight = textStyle.lineHeight || '1.6em'

      // Temp solution for white text - REMOVE it before finish
      if (textStyle.color === 'white' && textStyle.background === '#00000000')
        sticker.customize.blocks[0].textStyle.textShadow = '1px 1px 4px #ccc'

      // textStyle.styles
      const styles = textStyle.styles ?? []
      styles.map(style => {
        switch (style) {
          case 'bold':
            textStyle.fontWeight = 'bold'
            break
          case 'italic':
            textStyle.fontStyle = 'italic'
            break
          case 'underline':
            textStyle.textDecoration = 'underline'
            break
          case 'uppercase':
            textStyle.textTransform = 'uppercase'
            break
          case 'lowercase':
            textStyle.textTransform = 'lowercase'
            break

          default:
            break
        }
      })
      delete textStyle.styles

      // blockStyle
      sticker.customize.blocks[0].blockStyle = {
        padding: '5px 8px',
        fontSize: textStyle.fontSize
      }
      const blockStyle = sticker.customize.blocks[0].blockStyle
      if (Array.isArray(textStyle.alignment)) blockStyle.textAlign = textStyle.alignment[0]
      else if (textStyle.alignment) blockStyle.textAlign = textStyle.alignment
      if (sticker.isFromLibrary) {
        blockStyle.textAlign = 'center'
        delete sticker.isFromLibrary
      }
      delete textStyle.alignment
      delete textStyle.fontSize
    }

    // version
    sticker.version = '2022'
    sticker.transformed = true

    return true
  }
  return false
}
