import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12251f39 = () => interopDefault(import('../pages/clipr-usr-agents.vue' /* webpackChunkName: "pages/clipr-usr-agents" */))
const _688701ee = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _79febe3e = () => interopDefault(import('../pages/infos-789POIklm/index.vue' /* webpackChunkName: "pages/infos-789POIklm/index" */))
const _69b4e33d = () => interopDefault(import('../pages/opt-out.vue' /* webpackChunkName: "pages/opt-out" */))
const _4a2e9259 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _3eb8d2fa = () => interopDefault(import('../pages/under-18.vue' /* webpackChunkName: "pages/under-18" */))
const _0d566b4c = () => interopDefault(import('../pages/l/player/ads.vue' /* webpackChunkName: "pages/l/player/ads" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b19a727 = () => interopDefault(import('../pages/tag/header/_slug/index.vue' /* webpackChunkName: "pages/tag/header/_slug/index" */))
const _2d8970dd = () => interopDefault(import('../pages/tag/infeed/_slug/index.vue' /* webpackChunkName: "pages/tag/infeed/_slug/index" */))
const _0f40c99a = () => interopDefault(import('../pages/l/_slug/index.vue' /* webpackChunkName: "pages/l/_slug/index" */))
const _61db2308 = () => interopDefault(import('../pages/retail/_slug/index.vue' /* webpackChunkName: "pages/retail/_slug/index" */))
const _5f8a26fc = () => interopDefault(import('../pages/l/_slug/header.vue' /* webpackChunkName: "pages/l/_slug/header" */))
const _db14e89e = () => interopDefault(import('../pages/l/_slug/pingdom.vue' /* webpackChunkName: "pages/l/_slug/pingdom" */))
const _7592dfd3 = () => interopDefault(import('../pages/l/_slug/player/index.vue' /* webpackChunkName: "pages/l/_slug/player/index" */))
const _329b40e5 = () => interopDefault(import('../pages/l/_slug/simulator.vue' /* webpackChunkName: "pages/l/_slug/simulator" */))
const _e316bc54 = () => interopDefault(import('../pages/l/_slug/story.vue' /* webpackChunkName: "pages/l/_slug/story" */))
const _c849af7e = () => interopDefault(import('../pages/l/_slug/test.vue' /* webpackChunkName: "pages/l/_slug/test" */))
const _700456f1 = () => interopDefault(import('../pages/l/_slug/player/ads.vue' /* webpackChunkName: "pages/l/_slug/player/ads" */))
const _27778845 = () => interopDefault(import('../pages/l/_slug/player/dapi.vue' /* webpackChunkName: "pages/l/_slug/player/dapi" */))
const _659fac10 = () => interopDefault(import('../pages/l/_slug/player/mraid.vue' /* webpackChunkName: "pages/l/_slug/player/mraid" */))
const _68e52bea = () => interopDefault(import('../pages/l/_slug/player/small/index.vue' /* webpackChunkName: "pages/l/_slug/player/small/index" */))
const _7edccfa1 = () => interopDefault(import('../pages/l/_slug/player/test.vue' /* webpackChunkName: "pages/l/_slug/player/test" */))
const _26cc1e69 = () => interopDefault(import('../pages/l/_slug/player/small/test.vue' /* webpackChunkName: "pages/l/_slug/player/small/test" */))
const _27bc3612 = () => interopDefault(import('../pages/retail/_slug/_type/index.vue' /* webpackChunkName: "pages/retail/_slug/_type/index" */))
const _d2ff7e18 = () => interopDefault(import('../pages/retail/_slug/_type/_test/index.vue' /* webpackChunkName: "pages/retail/_slug/_type/_test/index" */))
const _036caf16 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _75d85bc3 = () => interopDefault(import('../pages/_slug/age.vue' /* webpackChunkName: "pages/_slug/age" */))
const _555544a2 = () => interopDefault(import('../pages/_slug/dapi.vue' /* webpackChunkName: "pages/_slug/dapi" */))
const _5b09f93b = () => interopDefault(import('../pages/_slug/mraid.vue' /* webpackChunkName: "pages/_slug/mraid" */))
const _5e4c839c = () => interopDefault(import('../pages/_slug/only-native.vue' /* webpackChunkName: "pages/_slug/only-native" */))
const _0e8d32d4 = () => interopDefault(import('../pages/_slug/pingdom.vue' /* webpackChunkName: "pages/_slug/pingdom" */))
const _3472c86a = () => interopDefault(import('../pages/_slug/simulator/index.vue' /* webpackChunkName: "pages/_slug/simulator/index" */))
const _1fd61719 = () => interopDefault(import('../pages/_slug/simulator-no-test.vue' /* webpackChunkName: "pages/_slug/simulator-no-test" */))
const _c91a6578 = () => interopDefault(import('../pages/_slug/social-post.vue' /* webpackChunkName: "pages/_slug/social-post" */))
const _ccfc948e = () => interopDefault(import('../pages/_slug/story.vue' /* webpackChunkName: "pages/_slug/story" */))
const _a68ae804 = () => interopDefault(import('../pages/_slug/test.vue' /* webpackChunkName: "pages/_slug/test" */))
const _1a0480dc = () => interopDefault(import('../pages/_slug/simulator/_type.vue' /* webpackChunkName: "pages/_slug/simulator/_type" */))

const _7bab8f04 = () => interopDefault(import('../pages/l/player/ads.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/clipr-usr-agents",
    component: _12251f39,
    name: "clipr-usr-agents"
  }, {
    path: "/iframe",
    component: _688701ee,
    name: "iframe"
  }, {
    path: "/infos-789POIklm",
    component: _79febe3e,
    name: "infos-789POIklm"
  }, {
    path: "/opt-out",
    component: _69b4e33d,
    name: "opt-out"
  }, {
    path: "/test",
    component: _4a2e9259,
    name: "test"
  }, {
    path: "/under-18",
    component: _3eb8d2fa,
    name: "under-18"
  }, {
    path: "/l/player/ads",
    component: _0d566b4c,
    name: "l-player-ads"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/tag/header/:slug",
    component: _4b19a727,
    name: "tag-header-slug"
  }, {
    path: "/tag/infeed/:slug",
    component: _2d8970dd,
    name: "tag-infeed-slug"
  }, {
    path: "/l/:slug",
    component: _0f40c99a,
    name: "l-slug"
  }, {
    path: "/retail/:slug",
    component: _61db2308,
    name: "retail-slug"
  }, {
    path: "/l/:slug?/header",
    component: _5f8a26fc,
    name: "l-slug-header"
  }, {
    path: "/l/:slug?/pingdom",
    component: _db14e89e,
    name: "l-slug-pingdom"
  }, {
    path: "/l/:slug?/player",
    component: _7592dfd3,
    name: "l-slug-player"
  }, {
    path: "/l/:slug?/simulator",
    component: _329b40e5,
    name: "l-slug-simulator"
  }, {
    path: "/l/:slug?/story",
    component: _e316bc54,
    name: "l-slug-story"
  }, {
    path: "/l/:slug?/test",
    component: _c849af7e,
    name: "l-slug-test"
  }, {
    path: "/l/:slug?/player/ads",
    component: _700456f1,
    name: "l-slug-player-ads"
  }, {
    path: "/l/:slug?/player/dapi",
    component: _27778845,
    name: "l-slug-player-dapi"
  }, {
    path: "/l/:slug?/player/mraid",
    component: _659fac10,
    name: "l-slug-player-mraid"
  }, {
    path: "/l/:slug?/player/small",
    component: _68e52bea,
    name: "l-slug-player-small"
  }, {
    path: "/l/:slug?/player/test",
    component: _7edccfa1,
    name: "l-slug-player-test"
  }, {
    path: "/l/:slug?/player/small/test",
    component: _26cc1e69,
    name: "l-slug-player-small-test"
  }, {
    path: "/retail/:slug?/:type",
    component: _27bc3612,
    name: "retail-slug-type"
  }, {
    path: "/retail/:slug?/:type/:test",
    component: _d2ff7e18,
    name: "retail-slug-type-test"
  }, {
    path: "/:slug",
    component: _036caf16,
    name: "slug"
  }, {
    path: "/:slug/age",
    component: _75d85bc3,
    name: "slug-age"
  }, {
    path: "/:slug/dapi",
    component: _555544a2,
    name: "slug-dapi"
  }, {
    path: "/:slug/mraid",
    component: _5b09f93b,
    name: "slug-mraid"
  }, {
    path: "/:slug/only-native",
    component: _5e4c839c,
    name: "slug-only-native"
  }, {
    path: "/:slug/pingdom",
    component: _0e8d32d4,
    name: "slug-pingdom"
  }, {
    path: "/:slug/simulator",
    component: _3472c86a,
    name: "slug-simulator"
  }, {
    path: "/:slug/simulator-no-test",
    component: _1fd61719,
    name: "slug-simulator-no-test"
  }, {
    path: "/:slug/social-post",
    component: _c91a6578,
    name: "slug-social-post"
  }, {
    path: "/:slug/story",
    component: _ccfc948e,
    name: "slug-story"
  }, {
    path: "/:slug/test",
    component: _a68ae804,
    name: "slug-test"
  }, {
    path: "/:slug/simulator/:type",
    component: _1a0480dc,
    name: "slug-simulator-type"
  }, {
    path: "/l/:slug/player",
    alias: "/y/:slug/player"
  }, {
    path: "/l/:slug/player/small",
    alias: "/y/:slug/player/small"
  }, {
    path: "/l/:slug/player/test",
    alias: "/y/:slug/player/test"
  }, {
    path: "/l/:slug/player/small/test",
    alias: "/y/:slug/player/small/test"
  }, {
    path: "/l/player/ads/small",
    component: _7bab8f04,
    props: () => ({
          isSmall: true
        })
  }, {
    path: "/l/player/ads/preroll",
    component: _7bab8f04,
    props: () => ({
          simulatePreroll: true
        })
  }, {
    path: "/l/player/ads/small/preroll",
    component: _7bab8f04,
    props: () => ({
          isSmall: true,
          simulatePreroll: true
        })
  }, {
    path: "/:slug/agefr1",
    component: _75d85bc3,
    props: () => ({ language: 'fr', subject: 'alcohol' })
  }, {
    path: "/:slug/ageen1",
    component: _75d85bc3,
    props: () => ({ language: 'en', subject: 'alcohol' })
  }, {
    path: "/age/under-18fr",
    component: _3eb8d2fa,
    props: () => ({ language: 'fr', subject: 'alcohol' })
  }, {
    path: "/age/under-18en",
    component: _3eb8d2fa,
    props: () => ({ language: 'en', subject: 'alcohol' })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
