const headersFunction = (req, res, next) => {
  const noCache = /(test|simulator|only-native)\/?$/i.test(req.url)
  if (noCache) {
    res.setHeader('Cache-Control', 'must-revalidate, no-cache, private')
  } else {
    res.setHeader('Cache-Control', 'max-age=600')
  }
  if (req.url.includes('%%')) {
    // decodeURI internal use by Nuxt will make the app crash
    // We need to rewrite the URL without %% occurrences
    res.writeHead(301, { Location: req.url.replace(/%%/g, '') })
    res.end()
  } else {
    next()
  }
}
export default headersFunction
