import config from '~/cliprConfig'
import { subdomain } from '~/services/subdomain'

export function getListRequestPath(_ureq, params, cliprTestMode) {
  return config.routes.story.list(params.slug, cliprTestMode) // Display list of clips
}

export function getIndividualRequestPath(
  { headers = {} } = {},
  params,
  cliprTestMode,
) {
  return config.routes.story.individual(
    subdomain(headers),
    params.slug,
    cliprTestMode,
  ) // Display only one clip
}

export function getIndividualRequestPathFromUrl(url, cliprTestMode) {
  if (url.slice(-1) === '/') {
    url = url.substr(0, url.length - 1)
  }
  url = url.replace('https://', '', url)
  url = url.replace('http://', '', url)

  // Now url should be form {subdomain}.clipr.co/{slug}/{possible test link}
  const firstSlashPos = url.indexOf('/')
  const secondSlashPos = firstSlashPos
    ? url.indexOf('/', firstSlashPos + 1)
    : -1
  let slug = null
  if (firstSlashPos !== -1) {
    if (secondSlashPos !== -1) {
      slug = url.substr(firstSlashPos + 1, secondSlashPos - firstSlashPos - 1)
    } else {
      slug = url.substr(firstSlashPos + 1)
    }
  }

  return getIndividualRequestPath(
    {
      headers: {
        host: url,
      },
    },
    { slug },
    cliprTestMode,
  )
}

export function getHubRequestPath(req, _uparams, cliprTestMode) {
  return config.routes.story.hub(subdomain(req.headers), cliprTestMode) // Display Hub of clips
}

export function getUserAgentsRequestPath() {
  return config.routes.story.userAgents()
}

function isATGNClip(shortCode) {
  return shortCode.indexOf('ATGN') === 0
}

export function getPixelImpressionRequestPath(storyData, conversion, hostUrl) {
  const shortCode = storyData.short_code || null
  if (shortCode && shortCode.length > 0) {
    if (isATGNClip(shortCode)) {
      const brandName = (storyData.content || {}).logo_top_left_title || null
      return config.routes.pixel.atgn_impression(
        brandName,
        conversion,
        storyData.ATGNTemplateName,
      )
    }
    // No impression visible pixel on non-auto generated clip
    if (conversion === 'impression') {
      return config.routes.pixel.impression(shortCode, conversion, hostUrl)
    }
  }
  return null
}

export function getPixelImpressionMultiplayerRequestPath(
  subdomainId,
  conversion,
) {
  return config.routes.pixel.multiplayer_impression(subdomainId, conversion)
}

export function getHashParamsFromUrl(hashString) {
  // Remove extra # if existing
  const fullHash = hashString.replace('#', '')

  // Turn string into table
  if (fullHash && fullHash.length > 0) {
    const decoded = decodeURI(fullHash)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
    try {
      return JSON.parse('{"' + decoded + '"}')
    } catch (e) {
      return {}
    }
  }
  return {}
}

export function getParamsFromSearchString(str) {
  return new URLSearchParams(str.replace('#', ''))
}

export function getHashParamFromUrl(hashString, key) {
  const params = getParamsFromSearchString(hashString)
  return params.get(key) || null
}
