/* eslint camelcase: 0 */
export const state = () => ({
  // Date of last story launching
  refTime: new Date(),
  // If false, update nothing
  shouldUpdateSessionDuration: true,
  // Current story being displayed
  currentShortCode: '',
  // Time spent on each story, in milliseconds
  timesShortCode: {},
})

export const getters = {
  storiesTimes: (state) => state.timesShortCode,
  currentStoryCode: (state) => state.currentShortCode,
  currentStoryTime: (state) =>
    state.currentShortCode && state.timesShortCode[state.currentShortCode]
      ? state.timesShortCode[state.currentShortCode]
      : Math.min(6000, new Date().getTime() - state.refTime.getTime()),
  // Avoid making analytics wrong by limiting diff time to 6000ms (should never been more than 3000ms theoretically
}

export const mutations = {
  editShortCode(state, short_code) {
    state.currentShortCode = short_code
  },
  editRefTime(state, newDate) {
    state.refTime = newDate
  },
  editTimes(state, payload) {
    if (state.timesShortCode[payload.key]) {
      state.timesShortCode[payload.key] += payload.value
    } else {
      const short_code = payload.key
      state.timesShortCode = Object.assign({}, state.timesShortCode, {
        [short_code]: payload.value,
      })
    }
  },
  editShouldUpdateSessionDuration(state, status) {
    if (!state.shouldUpdateSessionDuration && status) {
      // update was off and is now about to on
      // So reset refTime to avoid counting time while story was inactive
      state.refTime = new Date()
    }
    state.shouldUpdateSessionDuration = status
  },
  resetAllTimes(state) {
    state.refTime = new Date()
    state.timesShortCode = {}
  },
}

export const actions = {
  updateTimes: ({ commit, state }, short_code) => {
    if (state.shouldUpdateSessionDuration) {
      const newDate = new Date()
      const timeDiff = newDate.getTime() - state.refTime.getTime()

      // Story user is leaving : record time spent on it
      if (state.currentShortCode.length > 0) {
        commit('editTimes', {
          key: state.currentShortCode,
          value: timeDiff,
        })
        commit('editRefTime', newDate)
      }

      // Story user is landing on
      if (typeof short_code !== 'undefined') {
        commit('editShortCode', short_code)
      }
    }
  },
}
