import Vue from 'vue'

const EventBus = new Vue({})
const EventBusInstaller = {
  install(Vue) {
    Vue.prototype.$eventBus = EventBus
  },
}

Vue.mixin({
  data() {
    return {
      EventBusDetachers: [],
    }
  },
  beforeDestroy() {
    this.EventBusDetachers.forEach((detach) => detach())
  },
  methods: {
    attachEventBusListener(eventName, callback) {
      EventBus.$on(eventName, callback)
      this.EventBusDetachers.push(() => {
        EventBus.$off(eventName, callback)
      })
    },
  },
})

Vue.use(EventBusInstaller)

export default EventBus
