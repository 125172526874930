/* eslint-disable no-console */
import get from 'lodash/get'

/**
 * Plugin configuration
 */
interface Configuration {
  enabled: string | boolean | null
  storageKey: string
}

/**
 * Single message object in message queue
 */
interface QueueItem {
  tag: string
  printed: boolean
  messages: string[]
}

/**
 * Log color schemas
 */
interface Colors {
  [key: string]: string
}

/**
 * Output plugin object
 */
interface Log {
  [key: string]: (...messages: any[]) => void
}

/**
 * Default configuration for log plugin
 */
const configuration: Configuration = {
  enabled: false, // Enable logs by default for non-production environments
  storageKey: 'frontend-stories-log-enabled',
}

/**
 * Set of plugins helpers
 */
const helpers = {
  capitalize: (string: string) => string[0].toUpperCase() + string.slice(1),
}

/**
 * Colors for predefined list of tags
 */
const colors: Colors = {
  ads: '#dd0128',
  analytics: '#2096f3',
  default: '#aaaaaa',
  events: '#ffc107',
  gestures: '#129100',
  iframeEvent: '#d81b60',
  info: '#2196f3',
  pixels: '#8100ff',
  tcf: '#097340',
  visibility: '#795548',
}

/**
 * Store messages here if logs are not enabled
 */
const queue: QueueItem[] = []

/**
 * Default log without tag
 */
const logger = (...messages: string[]) => {
  const tag = messages.length > 1 ? messages[0] : 'default'
  const payload = messages.length > 1 ? messages.slice(1) : messages

  processLog(tag, payload)
}

/**
 * Support for dynamic tag
 */
const proxy = new Proxy(logger, {
  get(_utarget, prop: string) {
    return (...messages: string[]) => {
      processLog(prop, messages)
    }
  },
  set(_utarget, prop, value) {
    if (prop === 'enabled') {
      configuration.enabled = value

      try {
        window.sessionStorage.setItem(configuration.storageKey, value)
      } catch {
        console.warn('Cannot store logger settings in session storage')
      }

      if (value) {
        printLogsFromQueue()
      }
    }
    return true
  },
})

/**
 * Print or put into queue single log
 */
const processLog = (tag: string, messages: string[]) => {
  const color = colors[tag] || colors.default

  const output = [
    `%c ${helpers.capitalize(tag)} `,
    `background: ${color}; color: #ffffff`,
  ].concat(messages)
  if (configuration.enabled === true) {
    console.log.apply(this, output)
  } else if (
    configuration.enabled &&
    configuration.enabled.includes &&
    configuration.enabled.includes(tag)
  ) {
    console.log.apply(this, output)
  } else {
    queue.push({
      tag,
      printed: false,
      messages: output,
    })
  }
}

/**
 * Print all logs from queue
 */
const printLogsFromQueue = () => {
  console.group('Stored logs')

  queue.forEach((item) => {
    if (item.printed) {
      return
    }

    if (configuration.enabled === true && !item.printed) {
      console.log.apply(this, item.messages)

      item.printed = true
    } else if (
      typeof configuration.enabled === 'string' &&
      configuration.enabled &&
      configuration.enabled.includes &&
      configuration.enabled.includes(item.tag)
    ) {
      console.log.apply(this, item.messages)

      item.printed = true
    }
  })

  /* eslint-disable-next-line */
  console.groupEnd()
}

/**
 * Log plugin
 */

// @ts-ignore
export default ({ store }, inject) => {
  inject('log', proxy)

  function clearSessionStorage() {
    if (!get(window, 'sessionStorage')) return

    try {
      window.sessionStorage.removeItem(configuration.storageKey)
    } catch {
      console.warn('Cannot remove logger settings from session storage')
    }
  }

  function setSessionStorage() {
    if (!get(window, 'sessionStorage')) return
    try {
      if (configuration.enabled !== true) {
        const item = window.sessionStorage.getItem(configuration.storageKey)
        if (item === 'true') {
          configuration.enabled = true
        } else if (item === 'false') {
          configuration.enabled = false
        } else {
          configuration.enabled = window.sessionStorage.getItem(
            configuration.storageKey
          )
        }
      }

      printLogsFromQueue()
    } catch {
      console.warn('Cannot read logger settings from session storage')
    }
  }

  let canUseDeviceStorage = store.getters['tcf/canUseDeviceStorage']
  // @ts-ignore
  store.watch(
    () => store.getters['tcf/canUseDeviceStorage'],
    (value: boolean) => {
      canUseDeviceStorage = value
      if (value) {
        setSessionStorage()
      } else {
        clearSessionStorage()
      }
    }
  )

  if (canUseDeviceStorage) {
    setSessionStorage()
  } else {
    clearSessionStorage()
  }
}

export const log = proxy as unknown as Log
