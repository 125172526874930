import { Plugin } from '@nuxt/types'
import axios from 'axios'
import config from '../cliprConfig'
import { $cliprApi } from './cliprApi.d'

const cliprApi: Plugin = ({ store }, inject) => {
  inject('cliprApi', ((
    cnvShortCode: string | null,
    useAPIGWIfPossible: boolean,
    apiPathOverride?: string
  ) => {
    const defaultOptions = {
      baseURL:
        useAPIGWIfPossible && config.useAPIGW
          ? config.path.APIGW_BASE_URL
          : config.path.API_BASE_URL,
      headers: {
        'Content-Type':
          useAPIGWIfPossible && config.useAPIGW
            ? 'application/json'
            : 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      withCredentials: !(useAPIGWIfPossible && config.useAPIGW),
    }

    // Allow to override api path for new features
    if (apiPathOverride) {
      defaultOptions.baseURL = apiPathOverride
    }

    // Create instance
    const instance = axios.create(defaultOptions)

    // Add track id to GET params
    instance.interceptors.request.use((config) => {
      const cusID = store.getters['events/getCusId']
      if (cusID) {
        config.params = {
          ...config.params,
          cusID,
        }
      }
      const sessionId = store.getters['events/getSessionId'](cnvShortCode)
      if (sessionId) {
        config.params = {
          ...config.params,
          code: sessionId,
        }
      }
      return config
    })

    return instance
  }) as $cliprApi)
}

export default cliprApi
