export const state = () => ({
  type: null,
})

export const mutations = {
  type(state, type) {
    state.type = type
  },
}

export const getters = {
  isContentType: (state) => state.type === 'CONTENT',
}
