import uniqBy from 'lodash/uniqBy'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import isNumber from 'lodash/isNumber'
import snakeCase from 'lodash/snakeCase'
import get from 'lodash/get'

/**
 * Font aliases from old story builder
 * Should be removed when all old sticker gone
 */
const legacyStickerAliases = {
  bangers_sticker_font: 'Bangers',
  boogaloo_sticker_font: 'Boogaloo',
  catamaran_sticker_font: 'Catamaran',
  caveat_sticker_font: 'Caveat',
  kalam_sticker_font: 'Kalam',
  lato_sticker_font: 'Lato',
  merriweather_sticker_font: 'Merriweather',
  montserrat_sticker_font: 'Montserrat',
  noto_serif_sticker_font: 'Noto Serif',
  open_sans_sticker_font: 'Open Sans',
  poppins_sticker_font: 'Poppins',
  roboto_sticker_font: 'Roboto',
  source_sans_pro_sticker_font: 'Source Sans Pro',
  titillium_web_sticker_font: 'Titillium',
  work_sans_sticker_font: 'Work Sans',
}

const availableNewStickersFonts = [
  // Old stickers converted for new loader
  {
    family: 'Bangers',
    weights: ['Regular'],
  },
  {
    family: 'Boogaloo',
    weights: ['Regular'],
  },
  {
    family: 'Catamaran',
    weights: ['Regular'],
  },
  {
    family: 'Kalam',
    weights: ['Regular'],
  },
  {
    family: 'Lato',
    weights: ['Regular'],
  },
  {
    family: 'Merriweather',
    weights: ['Regular'],
  },
  {
    family: 'Montserrat',
    weights: ['Regular'],
  },
  {
    family: 'Open Sans',
    weights: ['Regular'],
  },
  {
    family: 'Source Sans Pro',
    weights: ['Regular'],
  },
  {
    family: 'Titillium',
    weights: ['Regular'],
  },
  // New stickers
  {
    family: 'Anton',
    weights: ['Regular'],
  },
  {
    family: 'Caveat',
    weights: ['Regular', 'Medium', 'Bold', 'SemiBold'],
  },
  {
    family: 'Dancing Script',
    weights: ['Regular', 'Medium', 'Bold', 'SemiBold'],
  },
  {
    family: 'DM Mono',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Light',
      'LightItalic',
    ],
  },
  {
    family: 'EB Garamond',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Bold',
      'BoldItalic',
      'SemiBold',
      'SemiBoldItalic',
      'ExtraBold',
      'ExtraBoldItalic',
    ],
  },
  {
    family: 'Noto Serif',
    weights: ['Regular', 'RegularItalic', 'Bold', 'BoldItalic'],
  },
  {
    family: 'Oswald',
    weights: ['Regular', 'Medium', 'Bold', 'SemiBold', 'Light', 'ExtraLight'],
  },
  {
    family: 'Poppins',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Bold',
      'BoldItalic',
      'SemiBold',
      'SemiBoldItalic',
      'ExtraBold',
      'ExtraBoldItalic',
      'Light',
      'LightItalic',
      'ExtraLight',
      'ExtraLightItalic',
      'Thin',
      'ThinItalic',
      'Black',
      'BlackItalic',
    ],
  },
  {
    family: 'Roboto',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Bold',
      'BoldItalic',
      'Light',
      'LightItalic',
      'Thin',
      'ThinItalic',
      'Black',
      'BlackItalic',
    ],
  },
  {
    family: 'Ubuntu',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Bold',
      'BoldItalic',
      'Light',
      'LightItalic',
    ],
  },

  {
    family: 'Work Sans',
    weights: [
      'Regular',
      'RegularItalic',
      'Medium',
      'MediumItalic',
      'Bold',
      'BoldItalic',
      'SemiBold',
      'SemiBoldItalic',
      'ExtraBold',
      'ExtraBoldItalic',
      'Light',
      'LightItalic',
      'ExtraLight',
      'ExtraLightItalic',
      'Thin',
      'ThinItalic',
      'Black',
      'BlackItalic',
    ],
  },
]
const newStickersFontWeightsNumericToStringMap = {
  100: 'Thin',
  200: 'ExtraLight',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
  800: 'ExtraBold',
  900: 'Black',
}

const newStickersFontWeightsStringToNumericMap = {
  Thin: 100,
  ExtraLight: 200,
  Light: 300,
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
  ExtraBold: 800,
  Black: 900,
}

function formatCDNUrl(url) {
  const CDN_URL = 'files.clipr.co'
  return url
    .replace('app.capteev.com', CDN_URL)
    .replace('app.clipr.co', CDN_URL)
    .replace('m.clipr.co', CDN_URL)
    .replace('dwxqdfkc8kedv.cloudfront.net', CDN_URL)
}

function getMainFontCSSText({ name = '' }) {
  if (name.length <= 3) return ''

  return `
  @font-face {
    font-family: theme_${name};
    src: url("/fonts/${name}-Regular.woff2") format("woff2"),url("/fonts/${name}-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: theme_${name};
    src: url("/fonts/${name}-Medium.woff2") format("woff2"),url("/fonts/${name}-Medium.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  } `
}

function getNewStickerFontData({
  family = '',
  weight = 'Regular',
  italic = false,
  alias = '',
}) {
  if (!family) return null

  // in case it comes as null, it won't take the default value
  if (weight === 'normal') weight = 'Regular'
  else if (weight === 'bold') weight = 'Bold'
  else if (!weight) weight = 'Regular'

  const font = find(availableNewStickersFonts, { family })

  if (!font) return null

  let weightNumericValue
  if (isNumber(weight)) {
    weightNumericValue = weight
    weight = newStickersFontWeightsNumericToStringMap[weight]
  } else {
    weightNumericValue = newStickersFontWeightsStringToNumericMap[weight]
  }
  if (!font.weights.includes(weight)) return null
  let fontFileName = `${family.replace(/ /g, '')}-${weight}`

  if (italic && font.weights.includes(`${weight}Italic`)) {
    fontFileName = `${fontFileName}Italic`
  }

  return {
    family,
    weight,
    weightNumericValue,
    fontFileName,
    italic,
    alias,
  }
}

function getNewStickerFontCSSText({
  family,
  alias,
  weight,
  weightNumericValue,
  fontFileName,
  italic,
}) {
  const fontFolderName = family.replace(/ /g, '')
  const fontFamily = alias || family
  const fontStyle = italic && !alias ? 'italic' : 'normal' // Old sticker fonts should be normal
  const cssText = [
    `
      @font-face {
        font-family: "${fontFamily}";
        src: url("/fonts/new-stickers-fonts/${fontFolderName}/woff2/${fontFileName}.woff2") format("woff2"),url("/fonts/new-stickers-fonts/${family}/woff/${fontFileName}.woff") format("woff");
        font-weight: ${weightNumericValue};
        font-style: ${fontStyle};
      } `,
  ]

  if (weight === 'Regular') {
    cssText.push(`
      @font-face {
        font-family: "${fontFamily}";
        src: url("/fonts/new-stickers-fonts/${fontFolderName}/woff2/${fontFileName}.woff2") format("woff2"),url("/fonts/new-stickers-fonts/${family}/woff/${fontFileName}.woff") format("woff");
        font-weight: normal;
        font-style: ${fontStyle};
      } `)
  } else if (weight === 'Bold') {
    cssText.push(`
      @font-face {
        font-family: "${fontFamily}";
        src: url("/fonts/new-stickers-fonts/${fontFolderName}/woff2/${fontFileName}.woff2") format("woff2"),url("/fonts/new-stickers-fonts/${family}/woff/${fontFileName}.woff") format("woff");
        font-weight: bold;
        font-style: ${fontStyle};
      } `)
  }

  return cssText.join('')
}

function getStickerFontCSSText({ name, source = '' }) {
  if (source.length <= 20) return ''

  const url = formatCDNUrl(source)
  const woffUrl = url.replace('.woff2', '.woff')

  return `
  @font-face {
    font-family: ${name};
    src: url("${url}") format("woff2"),url("${woffUrl}") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: ${name}_sticker_font;
    src: url("${url}") format("woff2"),url("${woffUrl}") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: ${snakeCase(name)}_sticker_font;
    src: url("${url}") format("woff2"),url("${woffUrl}") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  `
}

function extractFontsFromData({ stories = [] } = {}) {
  if (stories.length === 0 || !isArray(stories))
    return {
      main: [],
      stickers: [],
      newStickers: [],
    }

  const mainFonts = []
  let stickerFonts = []
  const newStickerFonts = []
  stories.forEach(
    ({ font_default: defaultFont, fonts = [], content = null } = {}) => {
      if (defaultFont && defaultFont !== 'Montserrat') {
        mainFonts.push({
          name: defaultFont.replace(/ /g, ''),
        })
      }
      if (isArray(fonts)) stickerFonts = stickerFonts.concat(fonts)
      const panels = get(content, 'story', [])
      if (panels && isArray(panels)) {
        panels.forEach((panel) => {
          const stickers = get(panel, 'stickers', [])
          if (isArray(stickers)) {
            stickers.forEach((stickerData) => {
              const sticker = get(stickerData, 'sticker', null)
              if (sticker && sticker.type === 'text') {
                const blocks = get(sticker, 'customize.blocks', [])

                if (isArray(blocks)) {
                  blocks.forEach((block) => {
                    const textStyle = get(block, 'textStyle', null)
                    if (textStyle) {
                      if (legacyStickerAliases[textStyle.fontFamily]) {
                        // For old legacy fonts
                        const font = getNewStickerFontData({
                          family: legacyStickerAliases[textStyle.fontFamily],
                          weight: textStyle.fontWeight,
                          italic: textStyle.fontStyle === 'italic',
                          alias: textStyle.fontFamily,
                        })

                        if (font) newStickerFonts.push(font)
                      } else {
                        // For regular fonts
                        const font = getNewStickerFontData({
                          family: textStyle.fontFamily,
                          weight: textStyle.fontWeight,
                          italic: textStyle.fontStyle === 'italic',
                        })

                        if (font) newStickerFonts.push(font)
                      }
                    }
                  })
                }
              }
            })
          }
        })
      }
    },
  )

  return {
    main: uniqBy(mainFonts, 'name'),
    stickers: uniqBy(stickerFonts, 'name'),
    newStickers: uniqBy(newStickerFonts, 'fontFileName'),
  }
}

export function getFontsCSSText(data) {
  const fonts = extractFontsFromData(data)

  const mainFontsCSSText = fonts.main.map(getMainFontCSSText)
  const stickerFontsCSSText = fonts.stickers.map(getStickerFontCSSText)
  const newStickerFontsCSSText = fonts.newStickers.map(getNewStickerFontCSSText)

  return mainFontsCSSText
    .concat(stickerFontsCSSText)
    .concat(newStickerFontsCSSText)
    .join(' ')
}

export function addFontStylesFromStoryData(storyData) {
  // Get font styles as text
  const fontStyles = getFontsCSSText({ stories: [storyData] })
  if (fontStyles && fontStyles.length > 0) {
    // Append to <head>
    const script = document.createElement('style')
    script.setAttribute('type', 'text/css')
    script.innerText = fontStyles
    document.head.appendChild(script)
  }
}
