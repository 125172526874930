export const state = () => ({
  current: null,
})

export const mutations = {
  current(state, story) {
    state.current = story
  },
}

export const getters = {
  currentStoryShortCode: (state) => {
    if (!state.current) return null
    return state.current.short_code
  },
}
