import get from 'lodash/get'
import { log } from '~/plugins/log'
import { TCF_CLIPR_VENDOR_ID } from '~/utilities/constants'

// const CLIPR_PURPOSES = [1, 7, 8, 10]

export default ({ store }, inject) => {
  // Inject $tcfApi() in Vue, context and store.
  inject(
    'tcfApi',
    /**
     * tcfApi Class
     * It will stub the __tcfApi global postMessage
     * And expose public function to be used in Vue
     */
    function tcfApi() {
      /** @see https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#is-there-a-sample-iframe-script-call-to-the-cmp-api */
      // start here at our window
      let frame = window

      // if we locate the CMP iframe we will reference it with this
      let cmpFrame

      // map of calls
      const cmpCallbacks = {}

      while (frame) {
        try {
          /**
           * throws a reference error if no frames exist
           */

          if (frame.frames.__tcfapiLocator) {
            cmpFrame = frame
            break
          }
        } catch (ignore) {}

        if (frame === window.top) {
          break
        }

        frame = frame.parent
      }

      /**
       * Set up a __tcfapi proxy method to do the postMessage and map the callback.
       * From the caller's perspective, this function behaves identically to the
       * CMP API's __tcfapi call
       */
      window.__tcfapi = function (cmd, version, callback, arg) {
        if (!cmpFrame) {
          // eslint-disable-next-line
          callback({ msg: 'CMP not found' }, false)
        } else {
          const callId = Math.random() + ''
          const msg = {
            __tcfapiCall: {
              command: cmd,
              parameter: arg,
              version,
              callId,
            },
          }

          /**
           * map the callback for lookup on response
           */
          cmpCallbacks[callId] = callback
          cmpFrame.postMessage(msg, '*')
        }
      }

      function postMessageHandler(event) {
        /**
         * when we get the return message, call the mapped callback
         */
        let json = {}

        try {
          /**
           * if this isn't valid JSON then this will throw an error
           */
          json =
            typeof event.data === 'string' ? JSON.parse(event.data) : event.data
        } catch (ignore) {}

        if (json && json.__tcfapiReturn) {
          const payload = json.__tcfapiReturn
          /**
           * messages we care about will have a payload
           */
          if (typeof cmpCallbacks[payload.callId] === 'function') {
            /**
             * call the mapped callback and then remove the reference
             */
            cmpCallbacks[payload.callId](payload.returnValue, payload.success)

            // in case of 'addEventListener' it will not be called again. So we need to keep the callback
            // cmpCallbacks[payload.callId] = null
          }
        }
      }

      window.addEventListener('message', postMessageHandler, false)

      /**
       * Callback for tcf api events
       * @param {tcData} TCData
       * @param {Boolean} success
       * @private
       * @returns {void}
       */
      this.handleTcData = (TCData, success) => {
        store.dispatch('events/addEvent', {
          key: 'PERFORMANCE_TCF_INIT',
          short_code: store?.state?.story?.current?.short_code,
          value: performance.now(),
        })

        if (!success || typeof TCData !== 'object') {
          log.tcf(
            'TCF Api NOT found, opting IN for cookies and performance measure',
          )
          store.dispatch('tcf/optInForDeviceStorage')
          store.dispatch('tcf/optInForPerformanceMeasure')
          return
        }

        if (!['useractioncomplete', 'tcloaded'].includes(TCData.eventStatus)) {
          return
        }

        const vendorConsents = get(TCData, 'vendor.consents', {})
        const cliprConsent = vendorConsents[TCF_CLIPR_VENDOR_ID] || false

        // if cliprConsent is false, we ignore this update because the values are initialized as "opted out" by default
        if (cliprConsent === false) {
          log.tcf('TCF Api found, but clipr consent is false')
          store.dispatch('tcf/optOutForDeviceStorage')
        } else {
          const consents = get(TCData, 'purpose.consents', {})
          store.dispatch('tcf/setConsents', consents)
        }
      }

      /**
       * addTcfListener
       * Use __tcfapi stub to communicate with tcfapi module
       * This function will call getTCData and try to resolve consent for cookie storage
       * When done, if user didn't consent cookie storage it will store cookieOptOut via setIsCookieOptOut
       *
       * |----------------------------------|
       * | Purpose | Behaviors              |
       * |----------------------------------|
       * |    1    | No cookie should be    |
       * |         | set on the user client |
       * |----------------------------------|
       * |    7    | No ad should be        |
       * |         | displayed (TODO)       |
       * |----------------------------------|
       * |   8/10  | Nothing...             |
       * |----------------------------------|
       *
       * @returns {void}
       * @public
       * @see {@link https://vendorlist.consensu.org/v2/vendor-list.json | Vendor List JSON}
       * @see {@link https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#gettcdata | IAB TCFv2 Spec : GetTcData}
       */
      this.addTcfListener = () => {
        // First event like tcloaded may be triggered before setting up addEventListener
        // so we first need to try to get TCData
        window.__tcfapi('getTCData', 2, this.handleTcData)

        // Now setting up eventListener for futur changes
        window.__tcfapi('addEventListener', 2, this.handleTcData)
      }
    },
  )
}
