export function subdomain(headers) {
  let host = headers.host || headers.referer || headers.referrer || 'm'
  host = host.replace('https://', '') // Case scheme in header
  host = host.replace('http://', '') // Case scheme in header
  if (host && host.length > 0 && host.includes('.')) {
    const subdomain = host.substr(0, host.indexOf('.'))
    return subdomain || 'm'
  }
  return host.includes('192.168') ? 'landing2' : 'm'
}
