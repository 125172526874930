export const state = () => ({
  // Can display clipr watermark
  watermark: false,
  height: 0,
})

export const getters = {
  getWatermark: (state) => state.watermark,
}

export const mutations = {
  setWatermark(state, watermark) {
    state.watermark = watermark
  },
  height(state, height) {
    state.height = height
  },
}
