export const actions = {
  // nuxtServerInit({ dispatch }, { $cookies }) {
  // Leaving the code here for reference
  // Do not uncomment. It will break behaviour of cusid
  // Because the app is cached, the server will not read the cookie for every request
  // this was moved to plugins/set-tcf-consent.js
  // const cookieOptOut = parseInt($cookies.get('cp_cnv_optout'))
  // if (cookieOptOut !== 1) {
  //   dispatch('tcf/optInForCliprCookies')
  // }
  // },
}
