//
//
//
//
//
//

export default {
  beforeMount() {
    window.addEventListener('message', this.onMessageReceived, false)
    this.$store.dispatch('storyRatio/windowResized')
    window.addEventListener('resize', this.onWindowResize)
  },
  mounted() {
    window.addEventListener(
      this.getVisibilityChangeEvent(),
      this.onVisibilityChange,
      { passive: false },
    )
    this.checkCSSAnimationState()
  },
  beforeDestroy() {
    window.removeEventListener(
      this.getVisibilityChangeEvent(),
      this.onVisibilityChange,
    )
    window.removeEventListener('message', this.onMessageReceived)
  },
  methods: {
    onWindowResize() {
      this.$store.dispatch('storyRatio/windowResized')
      this.$eventBus.$emit('window/resize')
    },
    getVisibilityChangeEvent() {
      let visibilityChangeEvent = 'visibilitychange'
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        visibilityChangeEvent = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        visibilityChangeEvent = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        visibilityChangeEvent = 'webkitvisibilitychange'
      }
      return visibilityChangeEvent
    },
    onVisibilityChange() {
      this.$store.commit('visibility/browserTabVisible', !document.hidden)
    },
    onMessageReceived(event) {
      const {
        key = null,
        value = null,
        firstTag = null,
        type = null,
      } = event?.data ?? {}
      if (key === null) return
      this.$log.iframeEvent(`RECEIVED event ${key} with payload`, {
        value,
        firstTag,
        type,
      })

      switch (key) {
        case 'IFRAME_VISIBILITY_OVER_50':
          this.$store.commit('visibility/visibleInsidePage', true)
          this.$store.commit(
            'visibility/iframeVisibleEventReceivedAtLeastOnce',
            true,
          )
          this.$store.commit('visibility/setIASVisible', true)
          break
        case 'IFRAME_VISIBILITY_UNDER_50':
          this.$store.commit('visibility/visibleInsidePage', false)
          this.$store.commit('visibility/setIASVisible', false)
          break
        case 'IFRAME_VISIBILITY_PERCENTAGE':
          this.$store.commit('visibility/iframeVisibilityPercentage', value)
          break
        case 'DS_ERRORS':
          this.relayErrorToSentry(value)
          break
      }

      this.$eventBus.$emit('iframe/message-received', {
        key,
        value,
        firstTag,
        type,
      })
    },
    relayErrorToSentry(relayErrorRequest) {
      this.$log.error('Error in delivery script', relayErrorRequest)
      const errorRelayResult = {
        success: true,
        requestId: relayErrorRequest.requestId,
        errors: relayErrorRequest.errors,
      }
      try {
        // relayErrorRequest.errors.forEach((error) => {
        //   this.$sentry.captureException(error)
        // })
      } catch (e) {
        errorRelayResult.success = false
      }

      // window.parent.postMessage(
      //   {
      //     key: 'DS_ERRORS_RESULT',
      //     value: errorRelayResult,
      //     name: window.name,
      //   },
      //   /* host */ '*'
      // )
    },
    checkCSSAnimationState() {
      if (this.cssCanAnimate) {
        this.$store.commit('environment/cssCanAnimate', true)
        return
      } else {
        this.cssCanAnimate = true
      }

      requestAnimationFrame(this.checkCSSAnimationState)
    },
  },
}
