import { log } from '~/plugins/log'

// Human readable errors from AYL docs
// See https://www.notion.so/clipr/AYL-Documentation-947d333f3286498a8bdedaabe13f4406
const aylErrorEnum = {
  1: 'SERVER_ERROR – Server response cannot be retreived',
  2: 'MODIFIER_ERROR – Placement config cannot be read',
  3: 'NO_CAMPAIGN – No advertiser bought the placement',
  4: 'EMPTY_CAMPAIGN_REQUEST – Ad response cannot be read',
  5: 'NO_LANDING – (Only if ad container is not given) No story URL to return.',
  6: 'NO_PLACEMENT – Configuration error, make sure the placement id is sent and valid.',
}

export function loadAylAd(placementId = '', element = null) {
  element = element || document.createElement('div')
  return new Promise((resolve, reject) => {
    log.ads('AYL_SDK_AD_LOAD_START', {
      placementId,
    })
    window.AylTag.requestAd(placementId, element, {}, (response) => {
      if (response.errorCode !== 0) {
        log.ads(
          'AYL_SDK_AD_LOAD_ERROR',
          aylErrorEnum[response.errorCode],
          'for placement',
          placementId,
          response,
        )
        // eslint-disable-next-line prefer-promise-reject-errors
        reject()
      } else {
        log.ads('AYL_SDK_AD_LOAD_SUCCESS', 'for placement', placementId, {
          placementId,
        })
        resolve()
      }
    })
  })
}
