import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { PostEventBody } from '@clipr/shared-types'
import uniqueId from 'lodash/uniqueId'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import find from 'lodash/find'
import some from 'lodash/some'
import config from '../cliprConfig'
import { triggerSocialEvent } from '../utilities/MarketingConversion'
import {
  adsShortCodesList,
  adsEventsWhitelist,
  adsShortCodes,
} from '../utilities/constants'
import { RootState } from './types'
import { log } from '~/plugins/log'
import { generateToken } from '~/utilities/functions'

// Any event triggered by code will be stored there
// Every 2 seconds, events will be sent to server
// then deleted from events

const getQuery = (param: string) => {
  if (typeof window === 'undefined') return null
  const query = new URLSearchParams(window.location.search)

  return query.get(param)
}

export const state = () => ({
  sessions: [] as PostEventBody[],
  cusId: '' as string,
  isTestMode: true as boolean,
  removedCodes: [] as string[],
  isCookieOptOut: true as boolean, // Defaulted to false, it mean that user will NOT get a cookie if there is no consent found
  playerPanelCount: 0 as number,
  queue: [],
})

export type EventsState = ReturnType<typeof state>

export const getters: GetterTree<EventsState, EventsState> = {
  getSessionId: (_, getters) => (shortCode: string) =>
    get(getters.sessionsByShortCode[shortCode], 'context.session', null),
  sessionsByShortCode: (state) => keyBy(state.sessions, 'context.clipCode'),
  sessionsById: (state) => keyBy(state.sessions, 'context.session'),
  getTestMode: (state) => state.isTestMode,
  getCusId: (state) => state.cusId,
}

export const mutations: MutationTree<EventsState> = {
  removeEventsForShortCode(state, shortCode) {
    if (!state.removedCodes.includes(shortCode)) {
      state.removedCodes.push(shortCode)
    }
  },

  increasePlayerPanelCount(state) {
    state.playerPanelCount++
  },

  // Method used to store events in case we are waiting for preroll
  // @ts-ignore
  // addEventInQueue(state, payload) {
  //   state.queue.push(payload)
  // },

  resetQueue(state) {
    state.queue = []
  },

  addEvent(state, { event, sessionId, shortCode }) {
    const session = find(state.sessions, [
      'context.session',
      sessionId,
    ]) as PostEventBody

    log.events(
      `Adding event ${event.action} for ${shortCode} with value`,
      event.value,
      `in session ${sessionId}`
    )

    session.events.push(event)

    // Send event to fb/google/snap if necessary
    triggerSocialEvent(event.action)
  },

  setIsTestMode(state, newVal) {
    state.isTestMode = newVal
  },

  resetEventsAndIncrementNumbers(state) {
    state.sessions.forEach((session) => {
      session.number++
      session.events = []
    })
  },

  saveSession(state, session) {
    state.sessions.push(session)
  },

  setCusId(state, cusId) {
    state.cusId = cusId
  },

  addContextInitToSession(state, { sessionId, init }) {
    const session = find(state.sessions, ['context.session', sessionId])
    if (session) session.context.init = init
  },
}

export const actions: ActionTree<EventsState, RootState> = {
  addEventsFromQueue({ state, commit, dispatch }) {
    if (state.queue.length !== 0) log.events('Adding events from queue')
    state.queue.forEach((payload) => {
      dispatch('addEvent', payload)
    })
    if (state.queue.length !== 0) log.events('Resetting queue')
    commit('resetQueue')
    return Promise.resolve()
  },
  increasePlayerPanelCount({ state, dispatch, commit }) {
    commit('increasePlayerPanelCount')
    dispatch('addPlayerEvent', {
      key: 'PLAYER_PANEL_COUNT',
      value: state.playerPanelCount,
    })
  },

  postEvents({ commit, state, rootState, rootGetters }, payload) {
    const timesByShortCode = payload.times
    const eventsToBeSent = state.sessions
      .filter(
        (session) =>
          session.context.clipCode === 'playerads' ||
          rootState.environment.carouselName === session.context.clipCode ||
          (!!timesByShortCode[session.context.clipCode] &&
            session.events.length !== 0)
      )
      .map((session) => ({
        ...session,
        events: [
          ...session.events,
          {
            action: 'UPDATE_SESSION_DURATION',
            value: timesByShortCode[session.context.clipCode],
          },
        ],
      }))
    const eventsBySessionId = keyBy(eventsToBeSent, 'context.session')

    // console.log(
    //   'Post Events',
    //   Object.values(eventsBySessionId)[0]?.events.map((event) => event.action),
    // )

    if (eventsBySessionId && Object.entries(eventsBySessionId).length > 0) {
      // Send events to server and time spent on each story
      const path = config.routes.events.post()
      const request = this.$cliprApi(null, true)
      const postData = {
        events: JSON.parse(JSON.stringify(eventsBySessionId)),
        test: state.isTestMode,
      }

      // avoid using await here, as it will block the rest of the code -> resetting events
      const currSession = find(eventsToBeSent, [
        'context.clipCode',
        rootState.times.currentShortCode,
      ])
      if (currSession) {
        const sessDuration = find(currSession.events, {
          action: 'UPDATE_SESSION_DURATION',
        })

        if (sessDuration) {
          log.events('UPDATE_SESSION_DURATION - ', sessDuration.value)
        }
      }
      const eventsRequestId = uniqueId('Events-Request-')

      log.events(
        `[${eventsRequestId}] Sending events to ${path} with data`,
        postData
      )

      // Reset events before sending the request
      // And increment sessions numbers for next sending
      commit('resetEventsAndIncrementNumbers')

      if (rootGetters['tcf/canSendEvents']) {
        return request.post(path, postData).catch((error) => {
          log.events(`[${eventsRequestId}] Sending events FAILED`, error)
          return Promise.reject(error)
        })
      } else {
        log.events(`[${eventsRequestId}] Sending events CANCELLED`)
        return Promise.resolve()
      }
    } else {
      // Reset events before sending the request
      // And increment sessions numbers for next sending
      commit('resetEventsAndIncrementNumbers')
    }
  },

  getSessionForCode({ dispatch, getters }, code) {
    const existingSession = getters.sessionsByShortCode[code]
    if (existingSession) {
      return existingSession
    }

    return dispatch('createSessionForCode', code)
  },

  async createSessionForCode({ state, commit, dispatch, getters }, code) {
    // we want to get cus id here, otherwise a tick is going to pass, meaning we will end up with 2 sessions for the same code if they are sent at the same time
    const cusId = await dispatch('getCusId')

    const existingSession = getters.sessionsByShortCode[code]
    if (existingSession) return existingSession

    const session = {
      number: 0,
      context: {
        session: generateToken(),
        clipCode: code,
        cusId,
        isTest: state.isTestMode,
        appVersion: config.appVersion,
      },
      events: [],
    }

    commit('saveSession', session)

    return session
  },

  getCusId({ commit, state, rootGetters }) {
    const cusId: string =
      getQuery('cusID') ||
      this.$cookies.get('cp_cnv_cusid') ||
      state.cusId ||
      generateToken()

    if (rootGetters['tcf/canUseCookies']) {
      this.$cookies.set('cp_cnv_cusid', cusId, {
        path: '/',
      })
    }

    commit('setCusId', cusId)

    return cusId
  },

  async addPlayerEvent({ rootState, commit, dispatch }, payload) {
    const carouselName = rootState.environment.carouselName
    const session = await dispatch('getSessionForCode', carouselName)

    if (!session.context.init && rootState.environment.brandInfo) {
      commit('addContextInitToSession', {
        sessionId: session.context.session,
        init: rootState.environment.brandInfo,
      })
    }

    commit('addEvent', {
      sessionId: session.context.session,
      shortCode: carouselName,
      event: {
        // id: '_' + Math.random().toString(36),
        action: payload.key,
        value: payload.value,
      },
    })
  },

  async addEvent({ commit, dispatch, state }, payload) {
    const shortCode = payload.short_code
    if (!shortCode) return false

    // only allow certain events for Ads
    if (
      some(adsShortCodes, (code) => payload.short_code.includes(code)) &&
      !adsEventsWhitelist.includes(payload.key)
    ) {
      return false
    }

    // Retrieve session id or create it if not existing yet
    if (state.removedCodes.includes(shortCode)) return false

    const session = await dispatch('getSessionForCode', shortCode)

    if (!session.context.init && payload.init) {
      commit('addContextInitToSession', {
        sessionId: session.context.session,
        init: payload.init,
      })
    }

    commit('addEvent', {
      sessionId: session.context.session,
      shortCode,
      event: {
        // id: '_' + Math.random().toString(36),
        action: payload.key,
        value: payload.value,
      },
    })
  },
}
