import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import some from 'lodash/some'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import deviceStorage from '~/static/deviceStorage.json'

import {
  TCF_PURPOSES,
  TCF_PURPOSE_ID_TO_KEY_MAP,
  TCF_PURPOSE_KEY_TO_ID_MAP,
} from '~/utilities/constants'
import { log } from '~/plugins/log'

export const state = () => ({
  cliprCookieConsent: false,
  consents: values(TCF_PURPOSES).map((purpose) => ({
    ...purpose,
    value: false,
    constName: TCF_PURPOSE_ID_TO_KEY_MAP[purpose.id],
  })),
  // we introduce this variable for the log plugin because otherwise it will clear the sessionStorage before this store is initialised
  initialised: false,
})

export const getters = {
  consentsById: (state) => keyBy(state.consents, 'id'),
  canUseDeviceStorage: (state, getters) =>
    !state.initialised ||
    getters.consentsById[TCF_PURPOSE_KEY_TO_ID_MAP.USE_DEVICE_STORAGE].value,
  canUseCookies: (state, getters) =>
    getters.canUseDeviceStorage && state.cliprCookieConsent,
  canSendEvents: (state, getters) =>
    (state.initialised &&
      getters.consentsById[
        TCF_PURPOSE_KEY_TO_ID_MAP.MEASURE_CONTENT_PERFORMANCE
      ].value) ||
    !state.initialised,
}

export const mutations = {
  setConsent(state, { id, value }) {
    const consent = state.consents.find((consent) => consent.id === id)
    if (consent) consent.value = value
  },
  setCliprCookieConsent(state, value) {
    state.cliprCookieConsent = value
  },
  setInitialised(state, value) {
    state.initialised = value
  },
}

export const actions = {
  setConsents({ state, commit, dispatch }, consents) {
    state.consents
      .filter((consent) => consent.id in consents)
      .forEach((consent) => {
        // We update all consent detected in the consents object
        // but we only log the ones that apply to clipr
        if (consent.applyToClipr) {
          log.tcf(
            'Setting consent for id',
            consent.id,
            `(${consent.constName}, ${consent.name})`,
            'to',
            consents[consent.id],
          )
        }

        const value = consents[consent.id]
        commit('setConsent', { id: consent.id, value })

        if (
          consent.id === TCF_PURPOSE_KEY_TO_ID_MAP.USE_DEVICE_STORAGE &&
          !value
        ) {
          log.tcf('Device Storage set to OFF, clearing device storage')
          dispatch('clearDeviceStorage')
        }
      })
    commit('setInitialised', true)
  },
  optInForDeviceStorage({ dispatch }) {
    dispatch(
      'tcf/setConsents',
      { [TCF_PURPOSE_KEY_TO_ID_MAP.USE_DEVICE_STORAGE]: true },
      { root: true },
    )
  },
  optInForPerformanceMeasure({ dispatch }) {
    dispatch(
      'tcf/setConsents',
      { [TCF_PURPOSE_KEY_TO_ID_MAP.MEASURE_CONTENT_PERFORMANCE]: true },
      { root: true },
    )
  },
  optOutForDeviceStorage({ dispatch }) {
    dispatch(
      'tcf/setConsents',
      { [TCF_PURPOSE_KEY_TO_ID_MAP.USE_DEVICE_STORAGE]: false },
      { root: true },
    )
    // if tcf consent is not given, we should remove the cookie
    dispatch('clearDeviceStorage')
  },
  optInForCliprCookies({ commit }) {
    log.tcf('Opting IN for clipr cookies')
    commit('setCliprCookieConsent', true)
    this.$cookies.remove('cp_cnv_optout')
  },
  optOutForCliprCookies({ commit, getters, dispatch }) {
    log.tcf('Opting OUT for clipr cookies')
    commit('setCliprCookieConsent', false)

    dispatch('clearDeviceCookies', true)

    if (getters.canUseCookies) {
      this.$cookies.set('cp_cnv_optout', 1, {
        path: '/',
      })
    }
  },
  clearDeviceStorage({ dispatch }) {
    dispatch('clearDeviceCookies')
    dispatch('clearDeviceLocalAndSessionStorage')
  },
  clearDeviceCookies(_, ignoreCliprCookie = false) {
    const cookiesCleared = []
    const patternCookies = []
    deviceStorage.disclosures
      .filter((c) => c.type === 'cookie')
      .forEach((disclosure) => {
        if (!ignoreCliprCookie && disclosure.identifier === 'cp_cnv_cusid')
          return
        if (disclosure.identifier.includes('*')) {
          patternCookies.push(disclosure.identifier)
        } else {
          cookiesCleared.push(disclosure.identifier)
          this.$cookies.remove(disclosure.identifier)
        }
      })
    if (patternCookies.length > 0) {
      const allCookies = Object.keys(this.$cookies.getAll())
      const regexes = patternCookies.map(
        (pattern) => new RegExp(`^${pattern.replace('*', '.*')}$`),
      )

      allCookies.forEach((cookie) => {
        if (some(regexes, (regex) => regex.test(cookie))) {
          cookiesCleared.push(cookie)
          this.$cookies.remove(cookie)
        }
      })
    }
    log.tcf(
      'Device Cookies CLEARED',
      cookiesCleared,
      'Clipr Cookie Ignored:',
      ignoreCliprCookie,
    )
  },
  clearDeviceLocalAndSessionStorage() {
    // we wrap with try/catch because on some browsers, directly accessing localStorage or sessionStorage can throw a SecurityError
    try {
      if (!get(window, 'localStorage') || !get(window, 'sessionStorage')) return
    } catch (_) {
      return
    }

    const clearedKeys = []
    const patternStorageKeys = []
    deviceStorage.disclosures
      .filter((c) => c.type === 'web')
      .forEach((disclosure) => {
        if (disclosure.identifier.includes('*')) {
          patternStorageKeys.push(disclosure.identifier)
        } else {
          window.localStorage.removeItem(disclosure.identifier)
          window.sessionStorage.removeItem(disclosure.identifier)
          clearedKeys.push(disclosure.identifier)
        }
      })

    if (patternStorageKeys.length > 0) {
      const storageKeys = uniq(
        Object.keys(window.localStorage).concat(
          Object.keys(window.sessionStorage),
        ),
      )
      const regexes = patternStorageKeys.map(
        (pattern) => new RegExp(`^${pattern.replace('*', '.*')}$`),
      )

      storageKeys.forEach((storageKey) => {
        if (some(regexes, (regex) => regex.test(storageKey))) {
          window.localStorage.removeItem(storageKey)
          window.sessionStorage.removeItem(storageKey)
          clearedKeys.push(storageKey)
        }
      })
    }
    log.tcf('Device Local and Session Storage CLEARED', clearedKeys)
  },
}
