import { log } from '~/plugins/log'

declare global {
  interface Window {
    snaptr: (event: string, tag: string, payload: any) => void
  }
}

export {}

export const triggerSocialEvent = (eventName: string) => {
  const eventInfo = {
    fbTag: '', // facebook event tag
    ggTag: '', // google event tag
    snapTag: '', // snap event tag
    type: '',
    description: '',
    category: '',
  }

  switch (eventName) {
    case 'SHOPPER_ADD_TO_CART':
      eventInfo.fbTag = 'Clipr_AddToCart'
      eventInfo.ggTag = 'clipr_add_to_cart'
      eventInfo.snapTag = 'ADD_CART'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Add product to cart'
      eventInfo.category = 'Button'
      break
    case 'SHOPPER_CHECKOUT':
      eventInfo.fbTag = 'Clipr_InitiateCheckout'
      eventInfo.ggTag = 'clipr_begin_checkout'
      eventInfo.snapTag = 'START_CHECKOUT'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Initiate checkout'
      eventInfo.category = 'Button'
      break
    case 'VIEW_REDEEM':
      eventInfo.fbTag = 'Clipr_RedeemOpened'
      eventInfo.ggTag = 'clipr_redeem_opened'
      eventInfo.snapTag = 'REDEEM_OPEN'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Redeem opened'
      eventInfo.category = 'Button'
      break
    case 'REDEEM_RECEIVE':
      eventInfo.fbTag = 'Clipr_RedeemReceived'
      eventInfo.ggTag = 'clipr_redeem_received'
      eventInfo.snapTag = 'REDEEM_RECEIVED'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Redeem received'
      eventInfo.category = 'Button'
      break
    case 'FORM_OPEN':
      eventInfo.fbTag = 'Clipr_FormOpened'
      eventInfo.ggTag = 'clipr_form_opened'
      eventInfo.snapTag = 'FORM_OPEN'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Form opened'
      eventInfo.category = 'Button'
      break
    case 'FORM_SUBMIT':
      eventInfo.fbTag = 'Clipr_FormSubmitted'
      eventInfo.ggTag = 'clipr_form_submitted'
      eventInfo.snapTag = 'FORM_SUBMITTED'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Form submitted'
      eventInfo.category = 'Button'
      break
    case 'STORY_INIT':
      eventInfo.fbTag = ''
      eventInfo.ggTag = 'story-init'
      eventInfo.snapTag = ''
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Story Initialization'
      eventInfo.category = 'Page'
      break
    case 'STORY_OPENED':
      eventInfo.fbTag = ''
      eventInfo.ggTag = 'story-opened'
      eventInfo.snapTag = ''
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Story launched'
      eventInfo.category = 'Page'
      break
    case 'VISIT_WEBSITE':
      // When clicking on "get more info"
      eventInfo.fbTag = 'Clipr_VisitWebsite'
      eventInfo.ggTag = 'clipr_visit_website'
      eventInfo.snapTag = 'VISIT_WEBSITE'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Opened website from description'
      eventInfo.category = 'Button'
      break
    case 'WEBSITE_REDIRECTION':
      // Redirection after form submission, reward copied...
      eventInfo.fbTag = 'Clipr_WebsiteRedirected'
      eventInfo.ggTag = 'clipr_website_redirected'
      eventInfo.snapTag = 'WEBSITE_REDIRECTION'
      eventInfo.type = 'event'
      eventInfo.description = 'Clipr - Redirected on website'
      eventInfo.category = 'Button'
      break
    default:
      // No event to track, can stop there
      return false
  }

  // @ts-ignore
  log.events('triggering social event', eventInfo)

  // @ts-ignore
  if (typeof window.ga !== 'undefined' && eventInfo.ggTag.length > 0) {
    // @ts-ignore
    window.ga('clipr.send', {
      hitType: eventInfo.type,
      eventCategory: eventInfo.category,
      eventAction: eventInfo.ggTag,
      eventLabel: eventInfo.description,
    })
    // @ts-ignore
    window.ga('customer.send', {
      hitType: eventInfo.type,
      eventCategory: eventInfo.category,
      eventAction: eventInfo.ggTag,
      eventLabel: eventInfo.description,
    })
  }
  // @ts-ignore
  if (typeof window.fbq !== 'undefined' && eventInfo.fbTag.length > 0) {
    // @ts-ignore
    window.fbq('trackCustom', eventInfo.fbTag)
  }
  if (typeof window.snaptr !== 'undefined' && eventInfo.snapTag.length > 0) {
    window.snaptr('track', eventInfo.snapTag, {
      description: eventInfo.description,
    })
  }

  return true
}
