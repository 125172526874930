import Vue from 'vue'
import axios from 'axios'
import config from '~/cliprConfig'

function MeasureEvent(eventType, _mediaId, _action) {
  const type = eventType
  const mediaId = _mediaId
  const action = _action
  let played = false
  let activated = false
  let frozen = false

  const timestamps = {
    isCreated: Date.now(),
    isActive: 0,
    isInactive: 0,
    isPlaying: 0,
  }

  let onPlayCallback = () => {}
  let onActiveCallback = () => {}

  this.getType = () => (frozen ? `${type}_FROZEN` : type)
  this.getMediaId = () => mediaId
  this.onActive = (callback) => (onActiveCallback = callback)
  this.onStartPlaying = (callback) => (onPlayCallback = callback)
  this.mediaIsInactive = () => (timestamps.isInactive = Date.now())
  this.getTimeToActive = () => timestamps.isActive - timestamps.isCreated
  this.getTimeToPlaying = () => timestamps.isPlaying - timestamps.isCreated
  this.isFrozen = () => (frozen = true)

  this.getReactionEventType = () => {
    if (action === 'GO_TO_NEXT_VIDEO') return 'VIDEO_ACTIVE_AFTER_TAP'
    if (action === 'GO_TO_NEXT_STORY') return 'VIDEO_ACTIVE_AFTER_SWIPE'
    if (action === 'GO_TO_PREVIOUS_VIDEO') return 'VIDEO_ACTIVE_AFTER_TAP'
    if (action === 'GO_TO_PREVIOUS_STORY') return 'VIDEO_ACTIVE_AFTER_SWIPE'

    return false
  }
  this.mediaIsActive = () => {
    if (activated) return false
    timestamps.isActive = Date.now()
    activated = true
    onActiveCallback(this)
  }
  this.mediaStartedPlaying = () => {
    if (played) return false
    timestamps.isPlaying = Date.now()
    played = true
    if (!frozen) onPlayCallback(this)
  }
}

const http = axios.create({
  baseURL: config.path.API_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
  withCredentials: true,
})
const measure = {}

measure.install = function (Vue) {
  Vue.prototype.$measure = new Vue({
    events: [],
    initialised: {},
    sessionId: Date.now(),
    data() {
      return {
        initialised: false,
        batchName: '',
        scenario: [],
      }
    },
    ongoingEvents: {},
    methods: {
      sendEvent(measureEvent, isReaction) {
        const type = isReaction
          ? measureEvent.getReactionEventType()
          : measureEvent.getType()
        const value = isReaction
          ? measureEvent.getTimeToActive()
          : measureEvent.getTimeToPlaying()

        const postData = {
          type,
          value,
          batch: this.batchName,
          session: this.$options.sessionId,
        }

        // return console.log(type, value, measureEvent.getMediaId())
        http.post(`${config.path.API_BASE_URL}/sub/ltcy`, postData)
      },
      init({ batchName = 'no_batch_id', startingMediaId } = {}) {
        this.batchName = batchName
        this.initialised = true

        const startingEvent = this.createEvent(
          'VIDEO_PLAY_AFTER_CAROUSEL_OPEN',
          startingMediaId,
        )
        startingEvent.onStartPlaying((mEvent) => {
          this.sendEvent(mEvent)
          http.get(`${config.path.API_BASE_URL}/clip/scenario`).then((res) => {
            this.scenario = res.data
            this.runScenario()
          })
        })
      },
      createEvent(type, mediaId, action) {
        // if existing event, we should remove it, we are done with it
        if (this.$options.ongoingEvents[mediaId]) {
          delete this.$options.ongoingEvents[mediaId]
        }

        this.$options.ongoingEvents[mediaId] = new MeasureEvent(
          type,
          mediaId,
          action,
        )

        return this.$options.ongoingEvents[mediaId]
      },
      mediaIsActive(mediaId) {
        if (!this.$options.ongoingEvents[mediaId]) return false
        this.$options.ongoingEvents[mediaId].mediaIsActive()
      },
      mediaIsInactive(mediaId) {
        if (!this.$options.ongoingEvents[mediaId]) return false
        this.$options.ongoingEvents[mediaId].mediaIsInactive()

        // don't remove for now. activeZIndex quickly switches on swipe and causes this test to fail
        // console.log("REMOVING MEASURE EVENT", mediaId)
        // delete this.$options.ongoingEvents[mediaId]
      },
      mediaStartedPlaying(mediaId) {
        if (!this.$options.ongoingEvents[mediaId]) return false
        this.$options.ongoingEvents[mediaId].mediaStartedPlaying()
      },
      runScenario() {
        const scene = this.scenario.shift()
        // eslint-disable-next-line no-console
        if (!scene) return console.log('END OF TEST')

        let mediaId = null
        if (scene.action === 'GO_TO_NEXT_VIDEO') {
          mediaId = window.getNextMediaId()
        } else if (scene.action === 'GO_TO_NEXT_STORY') {
          mediaId = window.getNextStoryMediaId()
        } else if (scene.action === 'GO_TO_PREVIOUS_VIDEO') {
          mediaId = window.getPreviousMediaId()
        } else if (scene.action === 'GO_TO_PREVIOUS_STORY') {
          mediaId = window.getPreviousStoryMediaId()
        }

        // if we have more iterations to run, we should put the scene back into sequence
        if (scene.times) {
          scene.times--
          this.scenario.unshift(scene)
        }

        this.runScene(mediaId, scene).then(() => this.runScenario())
      },
      runScene(mediaId, scene) {
        // console.log("run scene", scene.id, mediaId)

        return new Promise((resolve) => {
          let freezeTimer
          const measureEvent = this.createEvent(
            scene.eventType,
            mediaId,
            scene.action,
          )
          if (scene.waitForPlay) {
            freezeTimer = window.setTimeout(() => {
              measureEvent.isFrozen()
              this.sendEvent(measureEvent)
              resolve()
            }, 2000)
          }
          measureEvent.onStartPlaying((mEvent) => {
            // console.log("IS PLAYING", mEvent.getMediaId())
            this.sendEvent(mEvent)
            if (scene.waitForPlay) {
              window.clearTimeout(freezeTimer)
              resolve()
            }
          })
          measureEvent.onActive((mEvent) => {
            // console.log("IS ACTIVE", mEvent.getMediaId())
            this.sendEvent(mEvent, true)
          })

          this.$emit(scene.action)

          if (!scene.waitForPlay) window.setTimeout(() => resolve(), 200)
        })
      },
    },
  })
}

Vue.use(measure)
