const storyRatioFactor = 1.77
const defaultStorySize = {
  width: 400,
  height: 708,
}

function getAppContainerSize() {
  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    }
  }

  const sizer = document.getElementById('window-size-reference-element')
  if (!sizer) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const rect = sizer.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
  }
}

export const state = () => ({
  windowHeight: process.browser ? window.innerHeight : 0,
  windowWidth: process.browser ? window.innerWidth : 0,
  optimalHeight: null,
  emptySpace: null,
  enabled: false,
})

export const getters = {
  optimalHeight(state) {
    return state.enabled
      ? Math.min(state.windowHeight, state.windowWidth * storyRatioFactor)
      : null
  },
  emptySpaceHeight(state, getters) {
    return state.enabled ? state.windowHeight - getters.optimalHeight : 0
  },
  mediaLayersStyle(state, getters) {
    // default scaling style
    const style = {
      height: `${defaultStorySize.height}px`,
      width: `${defaultStorySize.width}px`,
      top: 0,
      left: 0,
    }
    if (state.enabled && getters.emptySpaceHeight > 0) {
      style.transform = `translate3d(0, 0, 1px) scale(${
        state.windowWidth / defaultStorySize.width
      })`
      style.transformOrigin = '0 0'
    } else {
      style.top = '50%'
      style.left = '50%'
      const currentScaleFactor = state.windowHeight / state.windowWidth
      if (currentScaleFactor < storyRatioFactor) {
        // means width is bigger, we increase scaling to match bigger width
        style.transform = `translate3d(-50%, -50%, 1px) scale(${
          state.windowWidth / defaultStorySize.width
        })`
      } else {
        // means height is bigger, we decrease scaling to match bigger height
        style.transform = `translate3d(-50%, -50%, 1px) scale(${
          state.windowHeight / defaultStorySize.height
        })`
      }
    }

    return style
  },
}

export const mutations = {
  enabled(state, enabled) {
    state.enabled = enabled
    const size = getAppContainerSize()
    // also update sizes
    // at runtime it won't have any value because it comes from nuxt
    state.windowHeight = size.height
    state.windowWidth = size.width
  },
  windowHeight(state, value) {
    state.windowHeight = value
  },
  windowWidth(state, value) {
    state.windowWidth = value
  },
}

export const actions = {
  windowResized({ commit }) {
    const size = getAppContainerSize()
    commit('windowHeight', size.height)
    commit('windowWidth', size.width)

    const sizeResponse = {
      windowHeight: size.height,
      windowWidth: size.width,
      fullWindowHeight: size.height,
      fullWindowWidth: size.width,
    }
    if (typeof window !== 'undefined') {
      sizeResponse.fullWindowHeight = window.innerHeight
      sizeResponse.fullWindowWidth = window.innerWidth
    }
    return Promise.resolve(sizeResponse)
  },
}
