/* eslint camelcase: 0 */
import { log } from '~/plugins/log'
export const state = () => ({
  iASVisible: false, // Procol IAS : visible if more than 50% of embedded iframe is visible
  iASCTAClicked: false,
  browserTabVisible: typeof document !== 'undefined' ? !document.hidden : true,

  // this is controlled by the scroll visibility inside a page; is controlled by script integration
  visibleInsidePage: true,

  // when story carousel is launched from thumbnail integration
  // when true, it should force mute and pause
  isMinimized: false,

  // in some cases we need to enforce rules based on if the visibility event was received the first time
  iframeVisibleEventReceivedAtLeastOnce: false,
  iframeVisibilityPercentageOverHalfAtLeastOnce: false,
  iframeVisibilityPercentage: 1,
  iframeVisibilityEventReceivedAtLeastOnce: false,
})

export const getters = {
  getIASVisible: (state) => state.iASVisible,
  getIASCTAClicked: (state) => state.iASCTAClicked,
}

export const mutations = {
  setIASVisible(state, value) {
    state.iASVisible = value
  },
  setIASCTAClicked(state, value) {
    state.iASCTAClicked = value
  },
  browserTabVisible(state, value) {
    log.visibility('BROWSER_TAB_VISIBLE', value)
    state.browserTabVisible = value
    state.iframeVisibilityEventReceivedAtLeastOnce = true
  },
  visibleInsidePage(state, value) {
    log.visibility('VISIBLE_INSIDE_PAGE', value)
    state.visibleInsidePage = value
    state.iframeVisibilityEventReceivedAtLeastOnce = true
  },
  isMinimized(state, value) {
    state.isMinimized = value
  },
  iframeVisibleEventReceivedAtLeastOnce(state, value) {
    state.iframeVisibleEventReceivedAtLeastOnce = value
  },
  iframeVisibilityPercentage(state, value) {
    state.iframeVisibilityPercentageOverHalfAtLeastOnce =
      state.iframeVisibilityPercentageOverHalfAtLeastOnce || value >= 0.5
    state.iframeVisibilityPercentage = value
  },
}
