export const CtaBasic = () => import('../../components/typology/templates/cta/CtaBasic.vue' /* webpackChunkName: "components/cta-basic" */).then(c => wrapFunctional(c.default || c))
export const CtaFullScreenTransparent = () => import('../../components/typology/templates/cta/CtaFullScreenTransparent.vue' /* webpackChunkName: "components/cta-full-screen-transparent" */).then(c => wrapFunctional(c.default || c))
export const CtaTwoLinesBlock = () => import('../../components/typology/templates/cta/CtaTwoLinesBlock.vue' /* webpackChunkName: "components/cta-two-lines-block" */).then(c => wrapFunctional(c.default || c))
export const CtaTwoLinesPure = () => import('../../components/typology/templates/cta/CtaTwoLinesPure.vue' /* webpackChunkName: "components/cta-two-lines-pure" */).then(c => wrapFunctional(c.default || c))
export const SelectCheckboxRadioType = () => import('../../components/form/multiple/SelectCheckboxRadioType.vue' /* webpackChunkName: "components/select-checkbox-radio-type" */).then(c => wrapFunctional(c.default || c))
export const SelectDropdownType = () => import('../../components/form/multiple/SelectDropdownType.vue' /* webpackChunkName: "components/select-dropdown-type" */).then(c => wrapFunctional(c.default || c))
export const CheckboxRadioType = () => import('../../components/form/single/CheckboxRadioType.vue' /* webpackChunkName: "components/checkbox-radio-type" */).then(c => wrapFunctional(c.default || c))
export const TextType = () => import('../../components/form/single/TextType.vue' /* webpackChunkName: "components/text-type" */).then(c => wrapFunctional(c.default || c))
export const TextareaType = () => import('../../components/form/single/TextareaType.vue' /* webpackChunkName: "components/textarea-type" */).then(c => wrapFunctional(c.default || c))
export const IgPostBig = () => import('../../components/new-stickers/ig-post/IgPostBig.vue' /* webpackChunkName: "components/ig-post-big" */).then(c => wrapFunctional(c.default || c))
export const IgPostSmall = () => import('../../components/new-stickers/ig-post/IgPostSmall.vue' /* webpackChunkName: "components/ig-post-small" */).then(c => wrapFunctional(c.default || c))
export const GooTextSticker = () => import('../../components/new-stickers/text/GooTextSticker.vue' /* webpackChunkName: "components/goo-text-sticker" */).then(c => wrapFunctional(c.default || c))
export const HalfHighlightTextSticker = () => import('../../components/new-stickers/text/HalfHighlightTextSticker.vue' /* webpackChunkName: "components/half-highlight-text-sticker" */).then(c => wrapFunctional(c.default || c))
export const HighlightTextSticker = () => import('../../components/new-stickers/text/HighlightTextSticker.vue' /* webpackChunkName: "components/highlight-text-sticker" */).then(c => wrapFunctional(c.default || c))
export const QuoteTextSticker = () => import('../../components/new-stickers/text/QuoteTextSticker.vue' /* webpackChunkName: "components/quote-text-sticker" */).then(c => wrapFunctional(c.default || c))
export const CtaZone = () => import('../../components/typology/action/CtaZone.vue' /* webpackChunkName: "components/cta-zone" */).then(c => wrapFunctional(c.default || c))
export const FormOptIn = () => import('../../components/typology/input/FormOptIn.vue' /* webpackChunkName: "components/form-opt-in" */).then(c => wrapFunctional(c.default || c))
export const FormWrapperZone = () => import('../../components/typology/input/FormWrapperZone.vue' /* webpackChunkName: "components/form-wrapper-zone" */).then(c => wrapFunctional(c.default || c))
export const FormZone = () => import('../../components/typology/input/FormZone.vue' /* webpackChunkName: "components/form-zone" */).then(c => wrapFunctional(c.default || c))
export const RedeemCard = () => import('../../components/typology/redeem/RedeemCard.vue' /* webpackChunkName: "components/redeem-card" */).then(c => wrapFunctional(c.default || c))
export const RedeemTopBtn = () => import('../../components/typology/redeem/RedeemTopBtn.vue' /* webpackChunkName: "components/redeem-top-btn" */).then(c => wrapFunctional(c.default || c))
export const RedeemZone = () => import('../../components/typology/redeem/RedeemZone.vue' /* webpackChunkName: "components/redeem-zone" */).then(c => wrapFunctional(c.default || c))
export const ShopperCart = () => import('../../components/typology/shopper/ShopperCart.vue' /* webpackChunkName: "components/shopper-cart" */).then(c => wrapFunctional(c.default || c))
export const ShopperSelect = () => import('../../components/typology/shopper/ShopperSelect.vue' /* webpackChunkName: "components/shopper-select" */).then(c => wrapFunctional(c.default || c))
export const ShopperZone = () => import('../../components/typology/shopper/ShopperZone.vue' /* webpackChunkName: "components/shopper-zone" */).then(c => wrapFunctional(c.default || c))
export const OptOutSwitch = () => import('../../components/gdpr/OptOutSwitch.vue' /* webpackChunkName: "components/opt-out-switch" */).then(c => wrapFunctional(c.default || c))
export const Privacy = () => import('../../components/gdpr/Privacy.vue' /* webpackChunkName: "components/privacy" */).then(c => wrapFunctional(c.default || c))
export const Hub = () => import('../../components/hub/Hub.vue' /* webpackChunkName: "components/hub" */).then(c => wrapFunctional(c.default || c))
export const HubCard = () => import('../../components/hub/HubCard.vue' /* webpackChunkName: "components/hub-card" */).then(c => wrapFunctional(c.default || c))
export const HubFilters = () => import('../../components/hub/HubFilters.vue' /* webpackChunkName: "components/hub-filters" */).then(c => wrapFunctional(c.default || c))
export const HubHeader = () => import('../../components/hub/HubHeader.vue' /* webpackChunkName: "components/hub-header" */).then(c => wrapFunctional(c.default || c))
export const NewSticker = () => import('../../components/new-stickers/NewSticker.vue' /* webpackChunkName: "components/new-sticker" */).then(c => wrapFunctional(c.default || c))
export const NewStickersLayer = () => import('../../components/new-stickers/NewStickersLayer.vue' /* webpackChunkName: "components/new-stickers-layer" */).then(c => wrapFunctional(c.default || c))
export const Retail = () => import('../../components/retail/Retail.vue' /* webpackChunkName: "components/retail" */).then(c => wrapFunctional(c.default || c))
export const FacebookPost = () => import('../../components/social-post/FacebookPost.vue' /* webpackChunkName: "components/facebook-post" */).then(c => wrapFunctional(c.default || c))
export const InstagramPost = () => import('../../components/social-post/InstagramPost.vue' /* webpackChunkName: "components/instagram-post" */).then(c => wrapFunctional(c.default || c))
export const InstagramPostUnderImages = () => import('../../components/social-post/InstagramPostUnderImages.vue' /* webpackChunkName: "components/instagram-post-under-images" */).then(c => wrapFunctional(c.default || c))
export const LinkedinPost = () => import('../../components/social-post/LinkedinPost.vue' /* webpackChunkName: "components/linkedin-post" */).then(c => wrapFunctional(c.default || c))
export const SocialPost = () => import('../../components/social-post/SocialPost.vue' /* webpackChunkName: "components/social-post" */).then(c => wrapFunctional(c.default || c))
export const SocialPostCTA = () => import('../../components/social-post/SocialPostCTA.vue' /* webpackChunkName: "components/social-post-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const SocialPostCarousel = () => import('../../components/social-post/SocialPostCarousel.vue' /* webpackChunkName: "components/social-post-carousel" */).then(c => wrapFunctional(c.default || c))
export const SocialPostHeader = () => import('../../components/social-post/SocialPostHeader.vue' /* webpackChunkName: "components/social-post-header" */).then(c => wrapFunctional(c.default || c))
export const SocialPostIcons = () => import('../../components/social-post/SocialPostIcons.vue' /* webpackChunkName: "components/social-post-icons" */).then(c => wrapFunctional(c.default || c))
export const SocialPostMedia = () => import('../../components/social-post/SocialPostMedia.vue' /* webpackChunkName: "components/social-post-media" */).then(c => wrapFunctional(c.default || c))
export const SocialPostText = () => import('../../components/social-post/SocialPostText.vue' /* webpackChunkName: "components/social-post-text" */).then(c => wrapFunctional(c.default || c))
export const TiktokPost = () => import('../../components/social-post/TiktokPost.vue' /* webpackChunkName: "components/tiktok-post" */).then(c => wrapFunctional(c.default || c))
export const TwitterPost = () => import('../../components/social-post/TwitterPost.vue' /* webpackChunkName: "components/twitter-post" */).then(c => wrapFunctional(c.default || c))
export const IconsFacebookIcons = () => import('../../components/social-post/icons/FacebookIcons.vue' /* webpackChunkName: "components/icons-facebook-icons" */).then(c => wrapFunctional(c.default || c))
export const IconsInstagramIcons = () => import('../../components/social-post/icons/InstagramIcons.vue' /* webpackChunkName: "components/icons-instagram-icons" */).then(c => wrapFunctional(c.default || c))
export const IconsLinkedinIcons = () => import('../../components/social-post/icons/LinkedinIcons.vue' /* webpackChunkName: "components/icons-linkedin-icons" */).then(c => wrapFunctional(c.default || c))
export const IconsTiktokIcons = () => import('../../components/social-post/icons/TiktokIcons.vue' /* webpackChunkName: "components/icons-tiktok-icons" */).then(c => wrapFunctional(c.default || c))
export const IconsTwitterIcons = () => import('../../components/social-post/icons/TwitterIcons.vue' /* webpackChunkName: "components/icons-twitter-icons" */).then(c => wrapFunctional(c.default || c))
export const MediaInteractiveStickers = () => import('../../components/stickers/MediaInteractiveStickers.vue' /* webpackChunkName: "components/media-interactive-stickers" */).then(c => wrapFunctional(c.default || c))
export const PollSticker = () => import('../../components/stickers/PollSticker.vue' /* webpackChunkName: "components/poll-sticker" */).then(c => wrapFunctional(c.default || c))
export const QuizSticker = () => import('../../components/stickers/QuizSticker.vue' /* webpackChunkName: "components/quiz-sticker" */).then(c => wrapFunctional(c.default || c))
export const RatingSticker = () => import('../../components/stickers/RatingSticker.vue' /* webpackChunkName: "components/rating-sticker" */).then(c => wrapFunctional(c.default || c))
export const ActionsZoneHeader = () => import('../../components/typology/ActionsZoneHeader.vue' /* webpackChunkName: "components/actions-zone-header" */).then(c => wrapFunctional(c.default || c))
export const ActionsZoneLayout = () => import('../../components/typology/ActionsZoneLayout.vue' /* webpackChunkName: "components/actions-zone-layout" */).then(c => wrapFunctional(c.default || c))
export const CtaButton = () => import('../../components/typology/CtaButton.vue' /* webpackChunkName: "components/cta-button" */).then(c => wrapFunctional(c.default || c))
export const IframeBrowser = () => import('../../components/typology/IframeBrowser.vue' /* webpackChunkName: "components/iframe-browser" */).then(c => wrapFunctional(c.default || c))
export const NewCTA = () => import('../../components/typology/NewCTA.vue' /* webpackChunkName: "components/new-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const AgeVerification = () => import('../../components/AgeVerification.vue' /* webpackChunkName: "components/age-verification" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsTags = () => import('../../components/AnalyticsTags.vue' /* webpackChunkName: "components/analytics-tags" */).then(c => wrapFunctional(c.default || c))
export const Branding = () => import('../../components/Branding.vue' /* webpackChunkName: "components/branding" */).then(c => wrapFunctional(c.default || c))
export const CTALink = () => import('../../components/CTALink.vue' /* webpackChunkName: "components/c-t-a-link" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const Clipr = () => import('../../components/Clipr.vue' /* webpackChunkName: "components/clipr" */).then(c => wrapFunctional(c.default || c))
export const CloseButton = () => import('../../components/CloseButton.vue' /* webpackChunkName: "components/close-button" */).then(c => wrapFunctional(c.default || c))
export const Media = () => import('../../components/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c))
export const MediaAdServerPlacement = () => import('../../components/MediaAdServerPlacement.vue' /* webpackChunkName: "components/media-ad-server-placement" */).then(c => wrapFunctional(c.default || c))
export const MediaPreview = () => import('../../components/MediaPreview.vue' /* webpackChunkName: "components/media-preview" */).then(c => wrapFunctional(c.default || c))
export const MediaStickers = () => import('../../components/MediaStickers.vue' /* webpackChunkName: "components/media-stickers" */).then(c => wrapFunctional(c.default || c))
export const MediaVideo = () => import('../../components/MediaVideo.vue' /* webpackChunkName: "components/media-video" */).then(c => wrapFunctional(c.default || c))
export const MultiPlayer = () => import('../../components/MultiPlayer.vue' /* webpackChunkName: "components/multi-player" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationBar = () => import('../../components/NavigationBar.vue' /* webpackChunkName: "components/navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const PauseOverlay = () => import('../../components/PauseOverlay.vue' /* webpackChunkName: "components/pause-overlay" */).then(c => wrapFunctional(c.default || c))
export const PixelUrl = () => import('../../components/PixelUrl.vue' /* webpackChunkName: "components/pixel-url" */).then(c => wrapFunctional(c.default || c))
export const ShadowGradient = () => import('../../components/ShadowGradient.vue' /* webpackChunkName: "components/shadow-gradient" */).then(c => wrapFunctional(c.default || c))
export const Slide = () => import('../../components/Slide.vue' /* webpackChunkName: "components/slide" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const Stories = () => import('../../components/Stories.vue' /* webpackChunkName: "components/stories" */).then(c => wrapFunctional(c.default || c))
export const StoriesBatch = () => import('../../components/StoriesBatch.vue' /* webpackChunkName: "components/stories-batch" */).then(c => wrapFunctional(c.default || c))
export const Story = () => import('../../components/Story.vue' /* webpackChunkName: "components/story" */).then(c => wrapFunctional(c.default || c))
export const StoryPreview = () => import('../../components/StoryPreview.vue' /* webpackChunkName: "components/story-preview" */).then(c => wrapFunctional(c.default || c))
export const VideoTester = () => import('../../components/VideoTester.vue' /* webpackChunkName: "components/video-tester" */).then(c => wrapFunctional(c.default || c))
export const Watermark = () => import('../../components/Watermark.vue' /* webpackChunkName: "components/watermark" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseInit = () => import('../../components/stories-base/globalInit/storiesBaseInit.js' /* webpackChunkName: "components/stories-base-init" */).then(c => wrapFunctional(c.default || c))
export const StoriesTextStickersPrepare = () => import('../../components/stories-base/globalInit/storiesTextStickersPrepare.js' /* webpackChunkName: "components/stories-text-stickers-prepare" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaSticker = () => import('../../components/stories-base/stickers/MediaSticker.vue' /* webpackChunkName: "components/stories-base-stickers-media-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersPollSticker = () => import('../../components/stories-base/stickers/PollSticker.vue' /* webpackChunkName: "components/stories-base-stickers-poll-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersQuizSticker = () => import('../../components/stories-base/stickers/QuizSticker.vue' /* webpackChunkName: "components/stories-base-stickers-quiz-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersRatingSticker = () => import('../../components/stories-base/stickers/RatingSticker.vue' /* webpackChunkName: "components/stories-base-stickers-rating-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseUtilsConstantsStories = () => import('../../components/stories-base/utils/constantsStories.js' /* webpackChunkName: "components/stories-base-utils-constants-stories" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseUtils = () => import('../../components/stories-base/utils/index.js' /* webpackChunkName: "components/stories-base-utils" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInitCommonFunctions = () => import('../../components/stories-base/globalInit/fontsInit/commonFunctions.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init-common-functions" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInit = () => import('../../components/stories-base/globalInit/fontsInit/index.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInitInjectGlobalCss = () => import('../../components/stories-base/globalInit/fontsInit/injectGlobalCss.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init-inject-global-css" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInitInjectGlobalCssOld = () => import('../../components/stories-base/globalInit/fontsInit/injectGlobalCssOld.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init-inject-global-css-old" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInitStickerFonts = () => import('../../components/stories-base/globalInit/fontsInit/stickerFonts.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init-sticker-fonts" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseGlobalInitFontsInitStickerFontsOld = () => import('../../components/stories-base/globalInit/fontsInit/stickerFontsOld.js' /* webpackChunkName: "components/stories-base-global-init-fonts-init-sticker-fonts-old" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersImgSticker = () => import('../../components/stories-base/stickers/MediaStickers/ImgSticker.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-img-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersSvgSticker = () => import('../../components/stories-base/stickers/MediaStickers/SvgSticker.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-svg-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextStickerBlock = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/TextStickerBlock.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker-block" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextStickerGroup = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/TextStickerGroup.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker-group" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextStickerText = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/TextStickerText.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker-text" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextSticker = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/index.vue' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextStickerDivStyle = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/utils/textStickerDivStyle.js' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker-div-style" */).then(c => wrapFunctional(c.default || c))
export const StoriesBaseStickersMediaStickersTextStickerSpanStyle = () => import('../../components/stories-base/stickers/MediaStickers/TextSticker/utils/textStickerSpanStyle.js' /* webpackChunkName: "components/stories-base-stickers-media-stickers-text-sticker-span-style" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
