import {
  adsLoadingType,
  adsShortCodes,
  AYL_SCRIPT_BASE_LINK,
  AYL_TAG_LINKS,
} from '../utilities/constants'
import { checkForAYLTag } from '~/utilities/functions'
import EventBus from '~/plugins/eventBus'
import config from '~/cliprConfig'
import { getIndividualRequestPathFromUrl } from '~/services/request'
// eslint-disable-next-line camelcase
import api_client from '~/utilities/CliprNoCookieAPI'
import { log } from '~/plugins/log'
import { addFontStylesFromStoryData } from '~/services/fonts'
import { loadAylAd } from '~/utilities/ads'

function extractPixelsFromDynAdInput(inputPixels, outputPixels, type) {
  for (let i = 0; i < 3; i++) {
    // Extract pixels from AYL response
    const conversionInput = ['impression', 'visibleImpression', 'CTA'][i]
    let conversionPixels =
      inputPixels && (inputPixels[conversionInput] || null)
        ? inputPixels[conversionInput]
        : []

    // Pixels could be received as string or as an array of string, each string representing an URL
    if (typeof conversionPixels === 'string') {
      conversionPixels = [conversionPixels]
    }

    // Store pixels in story data
    const conversionOutput = ['IMPRESSION', 'IMPRESSION_VISIBLE', 'CTA'][i]
    for (let j = 0; j < conversionPixels.length; j++) {
      if (conversionPixels[j].length > 0) {
        outputPixels[conversionOutput].push({
          src: conversionPixels[j],
          type,
        })
      }
    }
  }
  return outputPixels
}

export const state = () => ({
  AYLAdPlacement: '',
  adsLoadType: adsLoadingType.AYL_API,
  midrollAdCount: 0,
  forwardMoves: 2,
  aylTagLoading: false,
  preloadedAds: [],
  pendingAdPromise: null,
  prerollAdDisplayed: false,
})

export const mutations = {
  AYLAdPlacement(state, placement) {
    state.AYLAdPlacement = placement
  },
  adsLoadType(state, adsLoadType) {
    state.adsLoadType = adsLoadType
  },
  increaseMidrollCount(state) {
    state.midrollAdCount++
  },
  prerollAdDisplayed(state, prerollAdDisplayed) {
    state.prerollAdDisplayed = prerollAdDisplayed
  },
  increaseForwardMoves(state) {
    state.forwardMoves++
  },
  resetForwardMoves(state) {
    state.forwardMoves = 0
  },
  aylTagLoading(state, loading) {
    state.aylTagLoading = loading
  },
  addToPreloaded(state, ad) {
    state.preloadedAds.push(ad)
  },
  reAddToPreloaded(state, ad) {
    state.preloadedAds.unshift(ad)
  },
}

export const actions = {
  setAdCount({ commit, state }, story) {
    if (story.adCount) return Promise.resolve(story.adCount)

    // after giving a count, we increase the count to ensure uniqueness
    const count = state.midrollAdCount
    commit('increaseMidrollCount')
    return Promise.resolve(count)
  },
  updateAYLAdPlacement({ commit, state, dispatch }, placement) {
    commit('AYLAdPlacement', placement)
    if (state.adsLoadType === adsLoadingType.AYL_API) dispatch('loadAylTag')
  },
  load({ state, dispatch, commit, rootState }, data) {
    if (state.adsLoadType === adsLoadingType.EXTERNAL) {
      if (state.preloadedAds.length > 0)
        return Promise.resolve(state.preloadedAds.shift())

      const midrollAdCount = state.midrollAdCount
      const params = {
        adSlotIndex: midrollAdCount,
      }
      commit('increaseMidrollCount')
      log.iframeEvent('Sending CLIPR_DYN_ADS_REQUEST', params)
      window.parent.postMessage(
        {
          key: 'CLIPR_DYN_ADS_REQUEST',
          value: {
            params,
          },
        },
        '*',
      )
      return new Promise((resolve, reject) => {
        // TODO: split every IF into different loading methods
        EventBus.$once('ads/external-response', (response) => {
          if (response && response.url && response.url.length > 0) {
            dispatch('loadAdFromServer', response)
              .then((story) => {
                story.adCount = midrollAdCount
                if (story.adPlacement === adsShortCodes.MIDROLL) {
                  story.short_code = `${adsShortCodes.MIDROLL}_${midrollAdCount}`
                }
                story.isStoryAd = true
                story.content.story.forEach((media) => (media.isStoryAd = true))
                addFontStylesFromStoryData(story)
                resolve(story)
              })
              .catch(() => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject()
              })
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          }
        })
      })
    } else if (
      state.adsLoadType === adsLoadingType.AYL_API &&
      state.AYLAdPlacement
    ) {
      if (typeof window.AylTag === 'undefined') {
        dispatch('loadAylTag')
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      if (state.preloadedAds.length > 0)
        return Promise.resolve(state.preloadedAds.shift())
      commit('increaseMidrollCount')
      data.adCount = state.midrollAdCount
      if (data.adPlacement === adsShortCodes.MIDROLL) {
        data.short_code = `${adsShortCodes.MIDROLL}_${state.midrollAdCount}`
      }
      data.content.story[0].aylElement = document.createElement('div')

      return loadAylAd(
        state.AYLAdPlacement,
        data.content.story[0].aylElement,
      ).then(() => {
        return Promise.resolve(data)
      })
    } else if (state.adsLoadType === adsLoadingType.AYL_SCRIPT) {
      const el = document.createElement('script')
      if (
        rootState.environment.carouselName === 'decoist' &&
        rootState.environment.carouselEndpoint === 'l'
      ) {
        el.src = rootState.environment.isSmallMultiPlayer
          ? AYL_TAG_LINKS.DECOIST_SMALL_PLAYER
          : AYL_TAG_LINKS.DECOIST_PLAYER
      } else if (state.AYLAdPlacement) {
        el.src = AYL_SCRIPT_BASE_LINK + state.AYLAdPlacement
      } else {
        el.src = AYL_TAG_LINKS.DEFAULT
      }
      el.setAttribute('async', 'async')
      el.setAttribute('type', 'text/javascript')

      commit('increaseMidrollCount')
      data.adCount = state.midrollAdCount
      if (data.adPlacement === adsShortCodes.MIDROLL) {
        data.short_code = `${adsShortCodes.MIDROLL}_${state.midrollAdCount}`
      }
      data.content.story[0].aylElement = el
      return Promise.resolve(data)
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject()
  },
  loadAylTag({ commit, state }) {
    if (state.aylTagLoading) return false
    if (typeof window.AylTag !== 'undefined') {
      commit('aylTagLoading', false)
      return false
    }

    commit('aylTagLoading', true)
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://fo-static.omnitagjs.com'
    document.getElementsByTagName('head')[0].appendChild(script)
    checkForAYLTag()
      .then(() => {
        commit('aylTagLoading', false)
        EventBus.$emit('ads/aylTagLoaded')
      })
      .catch(() => {
        commit('aylTagLoading', false)
      })
  },
  setAdsLoadType({ commit }, adsLoadType) {
    commit('adsLoadType', adsLoadType)
  },
  loadAdFromServer(_, payload) {
    let API_ROOT = null
    if (payload.url.includes('.clipr.co')) {
      API_ROOT = config.path.API_BASE_URL.replace('.clipr.dev', '.clipr.co')
    } else if (payload.url.includes('.clipr.dev')) {
      API_ROOT = config.path.API_BASE_URL.replace('.clipr.co', '.clipr.dev')
    }
    const path = getIndividualRequestPathFromUrl(payload.url)
    const request = api_client(API_ROOT)
    return request.get(path).then(({ data = null } = {}) => {
      if (!data || !data.stories || data.stories.length === 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
      let pixels = {
        IMPRESSION: [],
        IMPRESSION_VISIBLE: [],
        CTA: [],
      }

      pixels = extractPixelsFromDynAdInput(
        payload.pixelTrackings || {},
        pixels,
        'image',
      )

      pixels = extractPixelsFromDynAdInput(
        payload.jsTrackings || {},
        pixels,
        'javascript',
      )

      return Promise.resolve({
        ...data.stories[0],
        pixels,
      })
    })
  },
}
