import BaseSimidCreative from '~/utilities/simid/creative'

export default ({ store }, inject) => {
  // Inject $simidPlugin() in Vue, context and store.
  inject('simidProtocol', function simidProtocol() {
    const simid = new BaseSimidCreative()
    simid.ready()

    window.simidProtocol = simid
    return simid
  })
}
