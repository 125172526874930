export const state = () => ({
  current: {},
  playing: false,
})

export const mutations = {
  currentMedia(state, media) {
    state.current = media
    // for videos we should reset the playing state
    // for images we consider it's playing as soon as it's displayed
    state.playing = media && media.type !== 'video'
  },
  playing(state, { media = {}, playing = false } = {}) {
    if (media.id !== state.current.id) return
    state.playing = playing
  },
}
