import { PRODUCT } from '~/utilities/constants'

export const state = () => ({
  isMultiPlayer: false,
  isSmallMultiPlayer: false,
  isSponsoredPlayer: false,
  cssCanAnimate: false,
  carouselEndpoint: null,
  carouselName: null,
  brandInfo: {
    b: '',
    o: '',
    t: '',
  },
  fullStoryViewedAtLeastOnce: false,
  soundActive: false,
  waitingToReceivePreroll: false,
  macrosUrl: '',
  product: PRODUCT.NONE,
  iframeBrowserOpened: false,
  shouldVerifyAge: false,
  ageRestrictionLanguage: 'en',
  ageRestrictionSubject: 'alcohol',
})

export const mutations = {
  iframeBrowserOpened(state, iframeBrowserOpened) {
    state.iframeBrowserOpened = iframeBrowserOpened
  },
  product(state, product) {
    state.product = product
  },
  isMultiPlayer(state, isMultiPlayer) {
    state.isMultiPlayer = isMultiPlayer
  },
  isSmallMultiPlayer(state, isSmallMultiPlayer) {
    state.isSmallMultiPlayer = isSmallMultiPlayer
    state.isMultiPlayer = true
  },
  cssCanAnimate(state, cssCanAnimate) {
    state.cssCanAnimate = cssCanAnimate
  },
  isSponsoredPlayer(state, isSponsoredPlayer) {
    state.isSponsoredPlayer = isSponsoredPlayer
  },
  carouselEndpoint(state, carouselEndpoint) {
    state.carouselEndpoint = carouselEndpoint
  },
  carouselName(state, carouselName) {
    state.carouselName = carouselName
  },
  brandInfo(state, brandInfo) {
    state.brandInfo.b = brandInfo.i
    state.brandInfo.o = brandInfo.o
    state.brandInfo.t = brandInfo.t
  },
  fullStoryViewedAtLeastOnce(state, fullStoryViewedAtLeastOnce) {
    state.fullStoryViewedAtLeastOnce = fullStoryViewedAtLeastOnce
  },
  soundActive(state, soundActive) {
    state.soundActive = soundActive
  },
  waitingToReceivePreroll(state, waitingToReceivePreroll) {
    state.waitingToReceivePreroll = waitingToReceivePreroll
  },
  macrosUrl(state, macrosUrl) {
    state.macrosUrl = macrosUrl
  },
  shouldVerifyAge(state, shouldVerifyAge) {
    state.shouldVerifyAge = shouldVerifyAge
  },
  ageRestrictionLanguage(state, ageRestrictionLanguage) {
    state.ageRestrictionLanguage = ageRestrictionLanguage
  },
  ageRestrictionSubject(state, ageRestrictionSubject) {
    state.ageRestrictionSubject = ageRestrictionSubject
  },
}

export const actions = {
  simulateError(_, message = 'Simulated error') {
    return Promise.reject(new Error(message))
  },
}
