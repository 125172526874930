import axios from 'axios'
import config from '../cliprConfig'

/**
 *
 * @param API_BASE_URL_OVERRIDE
 * @returns {defaultOptions}
 * @constructor
 */
const CliprNoCookieAPI = (API_BASE_URL_OVERRIDE: string) => {
  const defaultOptions = {
    baseURL: API_BASE_URL_OVERRIDE || config.path.API_BASE_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  }

  // Create instance
  return axios.create(defaultOptions)
}

export default CliprNoCookieAPI
