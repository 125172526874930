const dev = {
  useAPIGW: false,
  path: {
    API_BASE_URL: 'https://api.clipr.dev/api',
    // ASSET_ROOT: 'http://localhost:3000/_nuxt',
    APIGW_BASE_URL: '',
    ASSET_ROOT: 'https://assets.clipr.dev/_nuxt',
    BO_BASE_URL: 'https://app.clipr.dev',
    EVENT_ROUTER_BASE_URL: 'https://event.clipr.dev',
  },
  // Real value is set via NUXT_ENV_APP_VERSION and should look like 'env(branch):sha'
  // Example: 'production(master):b3d9c1f'
  appVersion: 'dev(dev):dev',
  x: true,
}

const prod = {
  useAPIGW: process.env.NUXT_ENV_USE_APIGW === '1',
  path: {
    API_BASE_URL: process.env.NUXT_ENV_API_BASE_URL,
    APIGW_BASE_URL: process.env.NUXT_ENV_APIGW_BASE_URL,
    ASSET_ROOT: process.env.NUXT_ENV_ASSET_ROOT,
    BO_BASE_URL: process.env.NUXT_ENV_BO_BASE_URL,
    EVENT_ROUTER_BASE_URL: process.env.NUXT_ENV_EVENT_ROUTER_BASE_URL,
  },
  appVersion: process.env.NUXT_ENV_APP_VERSION || 'undefined():undefined', // Undefined should never happen
  x: true,
}

const global = {
  routes: {
    autoRedirect: {
      open: (SHORT_CODE, GHOST_VISIT_CODE) =>
        '/' + SHORT_CODE + '/auto-redirect/' + GHOST_VISIT_CODE,
    },
    CGU: {
      open: (SHORT_CODE) => '/' + SHORT_CODE + '/info/cgu/',
    },
    story: {
      list: (API_KEY, TEST_MODE) =>
        `/header/${API_KEY}/` + (TEST_MODE ? 'test/' : ''),
      individual: (SUDOMAIN, SLUG, TEST_MODE) =>
        `/clip/${SUDOMAIN}/${SLUG}` + (TEST_MODE ? '/test/' : ''),
      hub: (SUDOMAIN, TEST_MODE) =>
        `/hub/${SUDOMAIN}/` + (TEST_MODE ? 'test/' : ''),
      userAgents: () => `/user-agents/`,
    },
    pixel: {
      impression: (SHORT_CODE, CONVERSION, HOST_DOM) =>
        `/px/${SHORT_CODE}/${CONVERSION}/` +
        (HOST_DOM ? `?hostDom=${HOST_DOM}` : ''),
      atgn_impression: (DOMAIN_ENCODED, CONVERSION, TEMPLATE_NAME) =>
        `/px/atgn/${CONVERSION}/?domain=${btoa(
          DOMAIN_ENCODED,
        )}&templateName=${TEMPLATE_NAME}`,
      multiplayer_impression: (SUBDOMAIN_ID, CONVERSION) =>
        `/px/multiplayer/${SUBDOMAIN_ID}/${CONVERSION}`,
    },
    form: {
      post: (SHORT_CODE, TEST_MODE) =>
        '/' + SHORT_CODE + '/inp/create/' + (TEST_MODE ? '?test=1' : ''),
    },
    events: {
      post: () => '/clip/events/',
    },
    interactive: {
      poll: {
        post: (SHORT_CODE, TEST_MODE) =>
          '/' + SHORT_CODE + '/poll/answer/' + (TEST_MODE ? '?test=1' : ''),
      },
      quiz: {
        post: (SHORT_CODE, TEST_MODE) =>
          '/' + SHORT_CODE + '/quiz/answer/' + (TEST_MODE ? '?test=1' : ''),
      },
      rating: {
        post: (SHORT_CODE, TEST_MODE) =>
          '/' + SHORT_CODE + '/rating/answer/' + (TEST_MODE ? '?test=1' : ''),
      },
    },
    shopper: {
      addToCart: (SHORT_CODE, TEST_MODE) =>
        '/' + SHORT_CODE + '/cart/create/' + (TEST_MODE ? '?test=1' : ''),
    },
    simulator: (SHORT_CODE) => `/clips/preview/${SHORT_CODE}`,
  },
  locale: {
    default: 'fr',
    supported: ['en', 'fr'],
  },
}

export default {
  ...global,
  ...(process.env.NODE_ENV === 'development' ? dev : prod),
}
